import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Autocomplete,
  TextField,
  Chip,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Alert,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Box,
  Divider,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { connect, useDispatch } from "react-redux";
import ConfirmationDialog from "../../../comm/ConfirmationDialog";
import {
  savePSYAnswer,
  goToQuestionNo,
  submitTest,
  setQuestionTime,
  saveQuestionViews,
} from "../../../../store/psychometric/actions";

import { useTranslation } from "react-i18next";
import CountDown from "react-countdown";
import CountDownTimer from "../../exam-portal/components/CountDownTimer";

const QuestionItem = (props) => {
  const {
    user,
    loading,
    question,
    savePSYAnswer,
    currentPSYQuestionNo,
    psychometricSession,
    goToQuestionNo,
    noOfQuestions,
    reshuffledOptions,
    onSubmit,
    setOnSubmit,
    submitTest,
    questionTime,
    setQuestionTime,
    saveQuestionViews,
    psychometricSessionLogs,
    exitFullScreen,
    feedbackQuestions,
    translate,
  } = props;

  const [selectedMatchOptions, setSelectedMatchOptions] = useState({});
  const [confirmSubmit, setConfirmSubmit] = useState(false);

  useEffect(() => {
    setSelectedMatchOptions({
      A: question.options.find(
        (option) => option.optionNumber === question.answer1
      ),
      B: question.options.find(
        (option) => option.optionNumber === question.answer2
      ),
      C: question.options.find(
        (option) => option.optionNumber === question.answer3
      ),
      D: question.options.find(
        (option) => option.optionNumber === question.answer4
      ),
    });
  }, [question]);

  const isMatchingOptionSelected = (option, currOption) => {
    let selected = false;
    question.options.forEach((opt) => {
      if (
        selectedMatchOptions[opt.optionNumber]?.optionNumber ===
          option.optionNumber &&
        currOption !== opt.optionNumber
      )
        selected = true;
    });

    return selected;
  };

  const isAllMatchingOptionsSelected = () => {
    let selected = true;
    question.options.forEach((opt) => {
      if (!selectedMatchOptions[opt.optionNumber]) selected = false;
    });

    return selected;
  };

  const [now, setNow] = useState(Date.now());

  const onNextQuestion = () => {
    if (currentPSYQuestionNo === noOfQuestions - 1) setOnSubmit(true);
    else goToQuestionNo(currentPSYQuestionNo + 1, () => setNow(Date.now()));
  };

  const counterDownRef = useRef();
  const startCounterDown = () => counterDownRef.current.start();

  useEffect(() => {
    if (!onSubmit && !!question && !!psychometricSession) {
      startCounterDown();
      saveQuestionViews({
        questionId: question.id,
        applicantSessionId: psychometricSession?.id,
      });
    }
  }, [question]);

  const getLetterNo = (i) => {
    return ["A", "B", "C", "D", "E", "F"][i];
  };

  const [applicantFeedbacks, setApplicantFeedbacks] = useState([]);

  // useEffect(() => {
  //   console.log(applicantFeedbacks);
  // }, [applicantFeedbacks]);

  return (
    <>
      <div
        className="col-12"
        style={{
          padding: "40px",
          paddingBottom: "40px",
          backgroundColor: "#dcf1ff",
          minHeight: "65vh",
        }}
      >
        {!onSubmit ? (
          <div>
            <div
              className="fw-bold mb-3 d-flex justify-content-between"
              style={{ fontSize: "18px" }}
            >
              <span className="pr-5">
                Q{currentPSYQuestionNo + 1}.
                {question.questionTypeId === 3 ? (
                  <>
                    {question.description.split("#_#")[0]}{" "}
                    {question.description.includes("#_#") && (
                      <span
                        className="border px-2 text-lowercase"
                        style={{ backgroundColor: "#fff", color: "blue" }}
                      >
                        {question.options.find((o) => !!o.selected)
                          ?.description || "--------------"}
                      </span>
                    )}
                    {question.description.split("#_#")[1]}
                  </>
                ) : (
                  <>{question.description}</>
                )}{" "}
                <strong className="ml-1">/{question.score} Marks</strong>
              </span>
              <div style={{ fontWeight: "bolder", color: "red" }}>
                <CountDown
                  ref={counterDownRef}
                  controlled={false}
                  onComplete={() =>
                    question.questionTypeId !== 5
                      ? savePSYAnswer(
                          {
                            applicantSessionId: psychometricSession?.id,
                            questionId: question.id,
                            answer1:
                              question.options.find(
                                (option) => !!option.selected
                              )?.optionNumber || null,
                            answer2: null,
                            answer3: null,
                            answer4: null,
                          },
                          () => {
                            onNextQuestion();
                          }
                        )
                      : savePSYAnswer(
                          {
                            applicantSessionId: psychometricSession?.id,
                            questionId: question.id,
                            answer1:
                              selectedMatchOptions["A"]?.optionNumber || null,
                            answer2:
                              selectedMatchOptions["B"]?.optionNumber || null,
                            answer3:
                              selectedMatchOptions["C"]?.optionNumber || null,
                            answer4:
                              selectedMatchOptions["D"]?.optionNumber || null,
                          },
                          () => {
                            onNextQuestion();
                          }
                        )
                  }
                  daysInHours
                  date={now + +(question.questionTimeInSec || 0) * 1000}
                />
              </div>
            </div>

            {[1, 3, 4].includes(question.questionTypeId) && (
              <div className="pl-4 mb-4">
                <table>
                  <tbody>
                    {question.options.map((option, i) => (
                      <tr key={option.optionNumber}>
                        <td style={{ width: "5px" }}>
                          <div
                            onClick={() => {
                              savePSYAnswer(
                                {
                                  applicantSessionId: psychometricSession?.id,
                                  questionId: question.id,
                                  answer1: option.optionNumber,
                                  answer2: null,
                                  answer3: null,
                                  answer4: null,
                                },
                                () => {}
                              );
                            }}
                            className={` d-inline cursor-pointer p-2 border ${
                              option.selected ? "bg-primary text-light" : ""
                            }  `}
                            style={{ borderRadius: "2px" }}
                          >
                            {getLetterNo(i)}
                          </div>{" "}
                        </td>
                        <td>
                          <div>
                            <FormControlLabel
                              value={option.optionNumber}
                              control={
                                <Radio
                                  size="small"
                                  onClick={(e) => {
                                    savePSYAnswer(
                                      {
                                        applicantSessionId:
                                          psychometricSession?.id,
                                        questionId: question.id,
                                        answer1: option.optionNumber,
                                        answer2: null,
                                        answer3: null,
                                        answer4: null,
                                      },
                                      () => {}
                                    );
                                  }}
                                />
                              }
                              label={option.description}
                              checked={option.selected}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {question.questionTypeId === 5 && (
              <div className="pl-4 mb-4">
                <table>
                  <tbody>
                    {question.options.map((opt, i) => (
                      <tr key={i}>
                        <td>
                          <div className="bg-light p-3">{opt.optionNumber}</div>{" "}
                        </td>
                        <td style={{ width: "50%" }}>
                          <div className="bg-light p-3">{opt.description}</div>
                        </td>
                        <td style={{ width: "50%" }}>
                          <Autocomplete
                            fullWidth
                            defaultValue={null}
                            value={
                              selectedMatchOptions[opt.optionNumber] || null
                            }
                            options={reshuffledOptions}
                            isOptionEqualToValue={(option, value) =>
                              option.optionNumber === value.optionNumber
                            }
                            getOptionLabel={(option) => option.matchWith || ""}
                            renderOption={(props, option) =>
                              !isMatchingOptionSelected(
                                option,
                                opt.optionNumber
                              ) && (
                                <Box component="li" {...props}>
                                  {option.matchWith || ""}
                                </Box>
                              )
                            }
                            onChange={(event, option) => {
                              setSelectedMatchOptions({
                                ...selectedMatchOptions,
                                [opt.optionNumber]: option || undefined,
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label=""
                                variant="outlined"
                                sx={{
                                  margin: "0 8px",
                                  bgcolor: "white",
                                  fontWeight: "bold",
                                  color: "blue",
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      border: "none",
                                    },
                                    "& input": {
                                      fontWeight: "bold",
                                      color: "blue",
                                    },
                                  },
                                }}
                                placeholder="Choose Option"
                              />
                            )}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            <div
              className="text-right"
              // style={{ backgroundColor: "#f0ffff" }}
            >
              <Button
                disabled={
                  !(
                    (!loading && !!question.answered) ||
                    (question.questionTypeId === 5 &&
                      !!isAllMatchingOptionsSelected())
                  )
                }
                onClick={() => {
                  question.questionTypeId === 5
                    ? savePSYAnswer(
                        {
                          applicantSessionId: psychometricSession?.id,
                          questionId: question.id,
                          answer1: selectedMatchOptions["A"].optionNumber,
                          answer2: selectedMatchOptions["B"].optionNumber,
                          answer3: selectedMatchOptions["C"].optionNumber,
                          answer4: selectedMatchOptions["D"].optionNumber,
                        },
                        () => {
                          setSelectedMatchOptions({});

                          onNextQuestion();
                        }
                      )
                    : onNextQuestion();
                }}
                color="primary"
                variant="contained"
                sx={{
                  borderRadius: "50px",
                  textAlign: "center",
                  minWidth: "180px",
                  padding: "4px 14px",
                  height: "40px",
                  boxShadow: "none",
                }}
              >
                {question.questionTypeId === 5 && <>Save and</>} Next
              </Button>
            </div>
          </div>
        ) : (
          <div className="mt-n4">
            {!psychometricSession?.submittedOn && (
              <>
                <h1 className="lead font-weight-bold">
                  Please provide your feedback to proceed.
                </h1>
                {feedbackQuestions.map((question) => (
                  <div className="text-left" key={question.id}>
                    <div>
                      {question.id}: {translate(question)}
                    </div>
                    <FormControl className="">
                      <RadioGroup row>
                        {question.options.map((option) => (
                          <FormControlLabel
                            key={option.id}
                            className="mb-0"
                            value={translate(option)}
                            control={<Radio size="small" />}
                            label={translate(option)}
                            onChange={(e) => {
                              const tempApplicantFeedbacks = [
                                ...applicantFeedbacks,
                              ];

                              const index = tempApplicantFeedbacks.findIndex(
                                ({ feedbackQuestionId }) =>
                                  feedbackQuestionId === question.id
                              );
                              if (index >= 0)
                                tempApplicantFeedbacks[index] = {
                                  ...tempApplicantFeedbacks[index],
                                  answer: e.target.value || null,
                                };
                              else
                                tempApplicantFeedbacks.push({
                                  applicantSessionId: psychometricSession?.id,
                                  feedbackQuestionId: question.id,
                                  answer: e.target.value || null,
                                  comment: null,
                                });

                              setApplicantFeedbacks(tempApplicantFeedbacks);
                            }}
                          />
                        ))}

                        <TextField
                          multiline
                          className="my-2"
                          size="small"
                          label="Comment"
                          placeholder="Comment"
                          variant="outlined"
                          value={
                            applicantFeedbacks.find(
                              ({ feedbackQuestionId }) =>
                                feedbackQuestionId === question.id
                            )?.comment || ""
                          }
                          onChange={(e) => {
                            const tempApplicantFeedbacks = [
                              ...applicantFeedbacks,
                            ];

                            const index = tempApplicantFeedbacks.findIndex(
                              ({ feedbackQuestionId }) =>
                                feedbackQuestionId === question.id
                            );
                            if (index >= 0)
                              tempApplicantFeedbacks[index] = {
                                ...tempApplicantFeedbacks[index],
                                comment: e.target.value || null,
                              };
                            else
                              tempApplicantFeedbacks.push({
                                applicantSessionId: psychometricSession?.id,
                                feedbackQuestionId: question.id,
                                answer: null,
                                comment: e.target.value || null,
                              });

                            if (
                              !!tempApplicantFeedbacks[index] &&
                              !tempApplicantFeedbacks[index]?.answer &&
                              !tempApplicantFeedbacks[index]?.comment
                            )
                              tempApplicantFeedbacks.splice(index, 1);

                            setApplicantFeedbacks(tempApplicantFeedbacks);
                          }}
                        />
                      </RadioGroup>
                    </FormControl>

                    <Divider />
                  </div>
                ))}
              </>
            )}

            <div>
              <div className=" justify-content-center text-center  align-items-center">
                {!psychometricSession?.submittedOn && (
                  <>
                    <Button
                      className="mt-2"
                      disabled={loading || applicantFeedbacks.length < 6}
                      onClick={() =>
                        submitTest(
                          {
                            applicantSessionId: psychometricSession?.id,
                            applicantFeedbacks,
                          },
                          exitFullScreen
                        )
                      }
                      color="primary"
                      variant="contained"
                      sx={{
                        borderRadius: "50px",
                        textAlign: "center",
                        minWidth: "200px",
                        padding: "4px 14px",
                        height: "60px",
                        boxShadow: "none",
                      }}
                    >
                      {loading ? "Please wait..." : "Get my results"}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* <div style={{ backgroundColor: "#dcf1ff" }}> */}
      {!onSubmit && (
        <div
          className="d-flex justify-content-center px-4 align-items-center"
          style={{
            opacity: "0.5",
          }}
        >
          <div className="">
            <small className="text-dark">S/N: {question.id} </small>
          </div>
          <List disablePadding dense>
            <ListItem className="pt-0 lead" style={{ cursor: "default" }}>
              <ListItemText
                primary={<small>{question.knowledgeLevelName} Level </small>}
                secondary={
                  <small>Domain: {question.knowledgeSubDomainName}</small>
                }
              />
            </ListItem>
          </List>
        </div>
      )}
      {/* </div> */}

      {/* {confirmSubmit && (
        <ConfirmationDialog
          btnMessage={`SUBMIT`}
          confirmationDialog={confirmSubmit}
          message={<>Are you sure you want to submit?</>}
          setConfirmationDialog={setConfirmSubmit}
          onYes={() => {
            submitTest(exitFullScreen);
          }}
        />
      )} */}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  questionTime,
  feedbackQuestions,
}) => ({
  user,
  loading,
  questionTime,
  feedbackQuestions,
});

export default connect(mapStateToProps, {
  savePSYAnswer,
  goToQuestionNo,
  submitTest,
  setQuestionTime,
  saveQuestionViews,
})(QuestionItem);
