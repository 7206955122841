import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningIcon from "@mui/icons-material/Warning";

const SessionLogs = ({ psychometricSessionLogs }) => {
  const formatedSessionLogs = () => {
    const logs = [];

    psychometricSessionLogs.forEach((log) => {
      const index = logs.findIndex((l) => l.name === log.action);

      if (index >= 0) {
        logs[index].logs.push(log);
      } else logs.push({ name: log.action, logs: [log] });
    });

    return logs;
  };

  return (
    <>
      {!!psychometricSessionLogs.length && (
        <div
          className="mt-2"
          style={{
            width: "30vw",
          }}
        >
          <Alert severity="error" icon={false} className="mb-2">
            <strong>Warnings:</strong>
            <p>
              {formatedSessionLogs().map((action, index) => (
                <span className="badge badge-warning mr-1" key={index}>
                  {action.name}{" "}
                  <span className="badge badge-danger mr-1">
                    {action?.logs?.length} times
                  </span>
                </span>
              ))}
            </p>
          </Alert>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ psychometricSessionLogs }) => ({
  psychometricSessionLogs,
});

export default connect(mapStateToProps, {})(SessionLogs);
