import {
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import colors from "../../utils/colors";
import moment from "moment/moment";
import CountDownTimer from "../exam-portal/components/CountDownTimer";
import { connect } from "react-redux";
import AdvertisementDetails from "./AdvertisementDetails";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AllCandidatesShortlistedDialog from "./AllCandidatesShortlistedDialog";
import AppealPreviewDialog from "./AppealPreviewDialog";
import AllCandidatesOnWrittenDialog from "./AllCandidatesOnWrittenDialog";
import AllCandidatesOnOralDialog from "./AllCandidatesOnOralDialog";
import AllCandidatesResultsDialog from "./AllCandidatesResultsDialog";
import {
  hideOrUnhideApplication,
  getSingleApplication,
} from "../../../store/applicant/actions";
import ConfirmationDialog from "../../comm/ConfirmationDialog";
import AllCandidatesOnWaitingDialog from "./AllCandidatesOnWaitingDialog";
import JobOfferDialog from "./JobOfferDialog";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import WrittenExamDetailDialog from "./WrittenExamDetailDialog";
import OralExamDetailDialog from "./OralExamDetailDialog";
import { Link } from "react-router-dom";
import Bowser from "bowser";
import { isBrowserSupported } from "../../utils";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import PsychometricTest from "../psychometric";

function ApplicationItem(props) {
  const {
    loading,
    application,
    roundedValue,
    isForView,
    hideOrUnhideApplication,
    getSingleApplication,
  } = props;
  const browser = Bowser.getParser(window.navigator.userAgent);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [showAdvertisementDetails, setShowAdvertisementDetails] =
    useState(false);
  const [showReason, setShowReason] = useState(false);
  const [showLastStatusMessage, setShowLastStatusMessage] = useState(false);

  const [
    showAllCandidatesShortlistedDialog,
    setShowAllCandidatesShortlistedDialog,
  ] = useState(false);
  const [
    showAllCandidatesOnWrittenDialog,
    setShowAllCandidatesOnWrittenDialog,
  ] = useState(false);
  const [showAllCandidatesOnOralDialog, setShowAllCandidatesOnOralDialog] =
    useState(false);
  const [showAllCandidatesResultsDialog, setShowAllCandidatesResultsDialog] =
    useState(false);

  const [
    showAllCandidatesOnWaitingDialog,
    setShowAllCandidatesOnWaitingDialog,
  ] = useState(false);

  const [showAppealPreviewDialog, setShowAppealPreviewDialog] = useState(false);

  const [appealIdToShow, setAppealIdToShow] = useState(null);

  const [confirmHideOrUnHideApplication, setConfirmHideOrUnHideApplication] =
    useState(false);

  const [showJobOfferDialog, setShowJobOfferDialog] = useState(false);
  const [showWrittenExamDetail, setShowWrittenExamDetail] = useState(false);
  const [showOralExamDetail, setShowOralExamDetail] = useState(false);
  const [showCheatedReason, setShowCheatedReason] = useState(false);

  const [doPsychometricTest, setDoPsychometricTest] = useState(false);

  return (
    <article
      role="region"
      aria-labelledby={`application-title-${application.id}`}
    >
      <Card
        className={`border ${roundedValue || "rounded"} bg-light`}
        elevation={isForView ? 0 : 1}
      >
        <CardHeader
          className="pb-0"
          title={
            <div>
              <small
                style={{ fontSize: "9px" }}
                className="badge badge-pill d-block px-0 text-left"
                aria-hidden="true"
              >
                <small aria-hidden="true">#{application.id}</small>
              </small>
              <button
                onClick={() => {
                  if (!isForView) setShowAdvertisementDetails(true);
                }}
                aria-label={
                  !isForView
                    ? `Application on ${application.positionName}, click to view advertisement details`
                    : `Application on ${application.positionName}`
                }
                className="btn btn-link text-left p-0"
              >
                <Typography
                  sx={{ fontSize: 16 }}
                  variant="h6"
                  component="h3"
                  id={`application-title-${application.id}`}
                  color={colors.blueLogo}
                  className="d-flex justify-content-between text-underline"
                  aria-hidden="true"
                >
                  {application.positionName}
                </Typography>
              </button>
            </div>
          }
          subheader={
            <Typography
              variant="h6"
              component="h4"
              className="text-capitalize"
              sx={{ fontSize: 13 }}
            >
              {application.entityName?.toLowerCase()}{" "}
              {!!application.entityAcronym && (
                <span>({application.entityAcronym})</span>
              )}
            </Typography>
          }
          action={
            !isForView && (
              <div className="d-flex align-items-center text-right">
                {!!application.writtenExamStarted &&
                  !application.writtenSubmitted &&
                  !application.isWrittenExamCanceled && (
                    <div>
                      {!!application.writtenAttended &&
                      !application.isSubmitted ? (
                        <div>
                          <Link
                            to={{
                              pathname: "/applicant/exam-portal",
                              state: { applicationId: application.id },
                            }}
                            style={{ textDecoration: "none" }}
                            className="mr-1"
                            aria-label={`Do exam for ${
                              application.positionName
                            } ${
                              application.entityName
                                ? " at " + application.entityName
                                : ""
                            }`}
                          >
                            <Button
                              disableElevation
                              disabled={!!application.isMarkedAsCheated}
                              size="small"
                              color="success"
                              className="mr-2"
                              variant="contained"
                            >
                              <span aria-hidden="true">Do exam</span>
                            </Button>
                          </Link>
                          {!isBrowserSupported(browser) && (
                            <span
                              className="text-danger"
                              style={{ fontSize: "10px" }}
                            >
                              <strong>Browser not supported</strong>
                            </span>
                          )}
                        </div>
                      ) : !!application.isSubmitted ? (
                        <span
                          className="badge badge-pill text-success"
                          aria-label={`You have submitted your exam on ${application?.positionName} at ${application?.entityName}`}
                        >
                          <i className="fas fa-check-double"></i>{" "}
                          <span aria-hidden="true">Submitted</span>
                        </span>
                      ) : (
                        <span
                          className="badge badge-pill text-danger"
                          aria-label={`You didn't attend your exam on ${application?.positionName} at ${application?.entityName}`}
                        >
                          <span aria-hidden="true">Not attended</span>
                        </span>
                      )}
                    </div>
                  )}

                {application.isHidden && (
                  <span
                    className="badge badge-pill text-secondary"
                    aria-label={`This application was hidden!`}
                  >
                    <span aria-hidden="true">Hidden</span>
                  </span>
                )}

                <IconButton
                  color="primary"
                  size="small"
                  onClick={handleOpenMenu}
                  aria-label="show more"
                >
                  <Badge color="info">
                    <MoreHorizIcon />
                  </Badge>
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleCloseMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 2,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 15,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    disabled={loading}
                    onClick={() => {
                      handleCloseMenu();
                      setConfirmHideOrUnHideApplication(true);
                    }}
                    className="text-dark font-weight-light"
                    aria-label={`Hide or unhide this application`}
                  >
                    {!application.isHidden && (
                      <VisibilityOffIcon className="mr-1" />
                    )}
                    {application.isHidden && (
                      <VisibilityIcon className="mr-1" />
                    )}

                    <span aria-hidden="true">
                      {application.isHidden ? "Unhide" : "Hide"}
                    </span>
                  </MenuItem>
                </Menu>
              </div>
            )
          }
        />
        <CardContent className="pt-0">
          <div className="d-flex align-items-top flex-wrap">
            {isForView && (
              <div className="d-flex flex-column mr-3">
                <div style={{ color: colors.blueLogo }}>
                  <CalendarMonthOutlinedIcon
                    style={{ fontSize: "12px", marginRight: "4px" }}
                  />
                  <Typography variant="caption">Posted on</Typography>
                </div>
                <Typography
                  variant="body2"
                  className="font-weight-light"
                  style={{ fontSize: "13px" }}
                >
                  {moment(application.openingDate).format("ll")}
                </Typography>
              </div>
            )}
            {isForView && (
              <div className="d-flex flex-column mr-3">
                <div style={{ color: colors.blueLogo }}>
                  <AccessTimeOutlinedIcon
                    style={{ fontSize: "12px", marginRight: "4px" }}
                  />
                  <Typography variant="caption">Deadline</Typography>
                </div>
                <Typography
                  variant="body2"
                  className="font-weight-light"
                  style={{ fontSize: "13px" }}
                >
                  {moment(application.closingDate).format("ll")}
                </Typography>
              </div>
            )}
            <div className="mr-2">
              <Typography variant="caption" style={{ color: colors?.blueLogo }}>
                Level:
              </Typography>
              <Typography
                variant="caption"
                className="font-weight-bold"
                style={{ fontSize: "13px" }}
              >
                {`${application.levelName}.${application.scaleName}`}
              </Typography>
            </div>
            <div className="mr-2">
              <Typography variant="caption" style={{ color: colors?.blueLogo }}>
                Post{application.numberOfPosts > 1 ? "s" : ""}:
              </Typography>
              <Typography
                variant="caption"
                className="font-weight-bold"
                style={{ fontSize: "13px" }}
              >
                {application.numberOfPosts}
              </Typography>
            </div>
            <span>
              <span
                className={`badge badge-pill border`}
                style={{ fontSize: 10 }}
              >
                {application.employeeGroupName}
              </span>{" "}
              {application.isInternal && (
                <span className="badge badge-info ml-2">Internal</span>
              )}
            </span>
          </div>
          <div className="d-flex justify-content-between flex-wrap">
            <div className="d-flex flex-column flex-sm-row justify-content-start align-items-center flex-wrap">
              <div
                className="mr-2"
                aria-label={`Applied on: ${moment(application.appliedOn).format(
                  "lll"
                )}`}
              >
                <Typography
                  variant="caption"
                  style={{ color: colors?.blueLogo }}
                  aria-hidden="true"
                >
                  Applied on:
                </Typography>
                <Typography
                  variant="caption"
                  className="font-weight-bold"
                  aria-hidden="true"
                >
                  {moment(application.appliedOn).format("lll")}
                </Typography>
              </div>
              <Divider />
              <div
                style={{ borderRadius: "6px" }}
                className="border bg-white d-flex flex-wrap align-items-center"
              >
                <div
                  className={`badge badge-pill text-${
                    application.apStatusId === 0
                      ? "secondary"
                      : application.apStatusId === 1
                      ? "success"
                      : "danger"
                  }`}
                >
                  <div className="d-inline-flex  flex-wrap align-items-center">
                    {application.apStatusId === 0 && (
                      <i className="fas fa-clock"></i>
                    )}
                    {application.apStatusId === 1 && (
                      <i className="fas fa-check-circle"></i>
                    )}
                    {[2, 3].includes(application.apStatusId) && (
                      <i className="fas fa-times-circle"></i>
                    )}
                    <button
                      onClick={() =>
                        setShowAllCandidatesShortlistedDialog(true)
                      }
                      className={`btn badge badge-pill text-underline ${
                        [2, 3].includes(application.apStatusId)
                          ? "text-danger"
                          : ""
                      } `}
                      aria-label={`Your application status is ${application.apStatusName}, click to see all candidates status`}
                    >
                      <span aria-hidden="true">{application.apStatusName}</span>
                    </button>

                    {application.psychometricScore !== null && (
                      <Tooltip title="Psychometric score">
                        <span
                          className={`badge badge-${
                            application.psychometricScore >= 50
                              ? "success"
                              : "danger"
                          }`}
                          style={{ cursor: "default" }}
                        >
                          {application.psychometricScore}%
                        </span>
                      </Tooltip>
                    )}
                    {!isForView &&
                      application.psychometricScore === null &&
                      !!application.willHavePsychometricTest &&
                      !!application.canDoPsychometricTest && (
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => setDoPsychometricTest(true)}
                        >
                          Do Psychometric Test <OpenInNewIcon />
                        </Button>
                      )}
                  </div>{" "}
                  {application.apStatusId === 2 && (
                    <button
                      onClick={() => setShowReason(true)}
                      className="btn badge badge-pill btn-link"
                      aria-label="Reason for rejection"
                    >
                      <span aria-hidden="true">Reason</span>
                    </button>
                  )}
                  {!!application.shortListAppealId && (
                    <button
                      onClick={() => {
                        setAppealIdToShow(application.shortListAppealId);
                        setShowAppealPreviewDialog(true);
                      }}
                      className="btn badge badge-pill text-warning font-weight-bold text-uppercase text-underline"
                      aria-label="View your appeal"
                    >
                      <span aria-hidden="true">Appealed</span>
                    </button>
                  )}
                </div>

                {application.apStatusId === 1 && (
                  <div className="d-inline-flex justify-content-center align-items-center">
                    {(application.writtenExamId || application.oralExamId) && (
                      <div>
                        <div className="d-inline-flex flex-column justify-content-center align-items-start">
                          {!!application.writtenExamId && (
                            <div
                              className={`badge badge-pill ${
                                !!application.writtenExamSessionId &&
                                !!application.writtenPublished
                                  ? "cursor-pointer"
                                  : ""
                              }`}
                            >
                              {!!application.writtenExamSessionId &&
                                !!application.writtenPublished && (
                                  <button
                                    onClick={() =>
                                      !!application.writtenExamSessionId &&
                                      !!application.writtenPublished &&
                                      setShowAllCandidatesOnWrittenDialog(true)
                                    }
                                    style={{ minWidth: "47px" }}
                                    className={`btn badge badge-${
                                      (application.writtenScore || 0) >= 50
                                        ? "success"
                                        : "danger"
                                    } mr-1`}
                                    aria-label={`You have got ${(
                                      (application.writtenScore || 0) /
                                      (!!application.oralExamId ? 2 : 1)
                                    ).toFixed(2)}
                                      ${
                                        !!application.oralExamId
                                          ? " over 50 in written exam"
                                          : "% overall score"
                                      }, click to see all candidates score`}
                                  >
                                    <span aria-hidden="true">
                                      {(
                                        (application.writtenScore || 0) /
                                        (!!application.oralExamId ? 2 : 1)
                                      ).toFixed(2)}
                                      /{!!application.oralExamId ? "50" : "%"}
                                    </span>
                                  </button>
                                )}
                              <button
                                onClick={() => {
                                  if (
                                    !!application.writtenExamSessionId &&
                                    !!application.writtenPublished
                                  )
                                    setShowAllCandidatesOnWrittenDialog(true);
                                  else setShowWrittenExamDetail(true);
                                }}
                                className={`btn badge badge-pill text-underline text-${
                                  !!application.isWrittenExamCanceled
                                    ? "danger"
                                    : !!application.writtenExamSessionId &&
                                      !!application.writtenPublished
                                    ? "primary"
                                    : "secondary"
                                }`}
                                aria-label={
                                  (!!application.writtenExamSessionId &&
                                    !!application.writtenPublished) ||
                                  !!application.isWrittenExamCanceled
                                    ? ""
                                    : `Click to view details for written exam!`
                                }
                              >
                                <span aria-hidden="true">
                                  {application.writtenExamStatus}
                                </span>
                              </button>
                              {!!application.isMarkedAsCheated && (
                                <>
                                  <span
                                    className={`badge badge-pill text-danger`}
                                  >
                                    <span className="d-inline-flex align-items-center">
                                      <i className="fas fa-solid fa-bug "></i>
                                      <span
                                        onClick={() =>
                                          setShowCheatedReason(true)
                                        }
                                        className="text-underline cursor-pointer ml-1"
                                      >
                                        Cheated
                                      </span>
                                    </span>

                                    <span
                                      onClick={() => setShowCheatedReason(true)}
                                      className="badge badge-pill font-weight-bold btn-link cursor-pointer"
                                    >
                                      Reason
                                    </span>
                                  </span>
                                </>
                              )}

                              {!!application.writtentAppealId && (
                                <button
                                  onClick={() => {
                                    setAppealIdToShow(
                                      application.writtentAppealId
                                    );
                                    setShowAppealPreviewDialog(true);
                                  }}
                                  className="btn badge badge-pill text-warning font-weight-bold text-uppercase text-underline cursor-pointer"
                                  aria-label="View your appeal for written exam"
                                >
                                  <span aria-hidden="true">Appealed</span>
                                </button>
                              )}
                            </div>
                          )}
                          {!!application.oralExamId && (
                            <div className={`badge badge-pill`}>
                              {!!application.oralExamSessionId &&
                                !!application.oralPublished && (
                                  <button
                                    onClick={() =>
                                      !!application.oralExamSessionId &&
                                      !!application.oralPublished &&
                                      setShowAllCandidatesOnOralDialog(true)
                                    }
                                    style={{ minWidth: "47px" }}
                                    className={`btn badge badge-${
                                      (application.oralScore || 0) >= 50
                                        ? "success"
                                        : "danger"
                                    } mr-1`}
                                    aria-label={`You have got ${(
                                      (application.oralScore || 0) /
                                      (!!application.writtenExamId ? 2 : 1)
                                    ).toFixed(2)}
                                      ${
                                        !!application.writtenExamId
                                          ? " over 50 in oral exam"
                                          : "% overall score"
                                      }, click to see all candidates score`}
                                  >
                                    <span aria-hidden="true">
                                      {(
                                        (application.oralScore || 0) /
                                        (!!application.writtenExamId ? 2 : 1)
                                      ).toFixed(2)}
                                      /
                                      {!!application.writtenExamId ? "50" : "%"}
                                    </span>
                                  </button>
                                )}
                              <button
                                onClick={() => {
                                  if (
                                    !!application.oralExamSessionId &&
                                    !!application.oralPublished
                                  )
                                    setShowAllCandidatesOnOralDialog(true);
                                  else setShowOralExamDetail(true);
                                }}
                                className={`btn badge text-underline text-${
                                  !!application.isOralExamCanceled
                                    ? "danger"
                                    : !!application.oralExamSessionId &&
                                      !!application.oralPublished
                                    ? "primary"
                                    : "secondary"
                                } `}
                                aria-label={
                                  (!!application.oralExamSessionId &&
                                    !!application.oralPublished) ||
                                  !!application.isOralExamCanceled
                                    ? ""
                                    : `Click to view details for oral exam!`
                                }
                              >
                                <span aria-hidden="true">
                                  {application.oralExamStatus}
                                </span>
                              </button>

                              {!!application.oralAppealId && (
                                <button
                                  onClick={() => {
                                    setAppealIdToShow(application.oralAppealId);
                                    setShowAppealPreviewDialog(true);
                                  }}
                                  className="btn badge badge-pill text-warning font-weight-bold text-uppercase text-underline"
                                  aria-label="View your appeal for oral exam"
                                >
                                  <span aria-hidden="true">Appealed</span>
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {!!(application.writtenScore || 0) &&
                      !!(application.oralScore || 0) && (
                        <button
                          className={`btn badge badge-pill text-underline text-${
                            (application.totalScore || 0) >= 70
                              ? "success"
                              : "danger"
                          }`}
                          onClick={() =>
                            setShowAllCandidatesResultsDialog(true)
                          }
                          aria-label={`${
                            (application.totalScore || 0) >= 70
                              ? "You have passed the exam"
                              : "You have not passed the exam"
                          } with ${(application.totalScore || 0).toFixed(
                            2
                          )}%, click to see all candidates results!`}
                        >
                          <span aria-hidden="true">
                            <span
                              className={`badge badge-${
                                (application.totalScore || 0) >= 70
                                  ? "success"
                                  : "danger"
                              } mr-1`}
                              style={{ minWidth: "47px" }}
                            >
                              {(application.totalScore || 0).toFixed(2)}%
                            </span>

                            <span className="badge">
                              {(application.totalScore || 0) >= 70
                                ? "Passed"
                                : "Not passed"}
                            </span>
                          </span>
                        </button>
                      )}

                    {!!application.waitingListStatusId && (
                      <button
                        className={`btn badge badge-pill text-underline text-${
                          application.waitingListStatusId === 1
                            ? "primary"
                            : "danger"
                        }`}
                        onClick={() =>
                          application.waitingListStatusId === 1 &&
                          setShowAllCandidatesOnWaitingDialog(true)
                        }
                        disabled={application.waitingListStatusId !== 1}
                        aria-label={`Your waiting list status is${
                          application.waitingListStatusName
                        } ${
                          application.waitingListStatusId === 1
                            ? ` with ${application.waitingDaysRemaining} days left`
                            : ""
                        }, click to see all candidates on waiting list`}
                      >
                        {application.waitingListStatusId === 1 && (
                          <i
                            className="fas fa-clock"
                            style={{ paddingRight: "2px" }}
                          ></i>
                        )}
                        {application.waitingListStatusId === 2 && (
                          <i
                            className="fas fa-times-circle"
                            style={{ paddingRight: "2px" }}
                          ></i>
                        )}
                        {application.waitingListStatusName}
                        {application.waitingListStatusId === 1 && (
                          <small aria-hidden="true">
                            <span
                              className="badge badge-primary border"
                              style={{
                                marginLeft: "2px",
                              }}
                            >
                              {application.waitingDaysRemaining} days left
                            </span>
                          </small>
                        )}
                      </button>
                    )}

                    {!!application.jobOfferId && (
                      <button
                        className={`btn badge badge-pill text-underline text-${
                          [2, 5].includes(application.jobOfferStatusId)
                            ? "success"
                            : [3, 4].includes(application.jobOfferStatusId)
                            ? "danger"
                            : application.jobOfferStatusName === "Offer expired"
                            ? "warning"
                            : "primary"
                        }`}
                        onClick={() => setShowJobOfferDialog(true)}
                        aria-label={`Your job offer status is ${application.jobOfferStatusName}, click to see job offer details`}
                      >
                        {application.jobOfferStatusId === 1 && (
                          <i
                            className="fas fa-clock"
                            style={{ paddingRight: "2px" }}
                          ></i>
                        )}
                        {[2].includes(application.jobOfferStatusId) && (
                          <i
                            className="fas fa-check-circle"
                            style={{ paddingRight: "2px" }}
                          ></i>
                        )}
                        {[3, 4].includes(application.jobOfferStatusId) && (
                          <i
                            className="fas fa-times-circle"
                            style={{ paddingRight: "2px" }}
                          ></i>
                        )}
                        {[5].includes(application.jobOfferStatusId) && (
                          <i
                            className="fas fa-briefcase"
                            style={{ paddingRight: "2px" }}
                          ></i>
                        )}
                        <span aria-hidden="true">
                          {application.jobOfferStatusName}
                        </span>
                      </button>
                    )}
                  </div>
                )}

                {application.adStatusId === 8 && (
                  <button
                    className={`btn badge badge-pill cursor-pointer text-underline text-danger`}
                    onClick={() => setShowLastStatusMessage(true)}
                    aria-label={`The advertisement status is ${application.adStatusName}, click to see the reason`}
                  >
                    <i
                      className="fas fa-times-circle"
                      style={{ paddingRight: "2px" }}
                    ></i>

                    <span aria-hidden="true">{application.adStatusName}</span>
                  </button>
                )}
              </div>
            </div>
            <div>
              {application.apStatusId === 1 &&
                !application.isWrittenExamCanceled && (
                  <div className="text-center d-flex flex-column align-items-center">
                    {!!application.timeLeftToStartWritten &&
                      !!application.writtenVenue &&
                      !application.writtenExamStarted && (
                        <small style={{ fontSize: "10px" }}>
                          Online exam scheduled{" "}
                          <span className="text-primary text-capitalize">
                            @{application.writtenVenue.toLowerCase()}
                          </span>
                        </small>
                      )}

                    {!!application.timeLeftToStartWritten &&
                      !application.writtenExamStarted && (
                        <CountDownTimer
                          counterType="small"
                          defaultLeftDifferent={
                            +(application.timeLeftToStartWritten || 0)
                          }
                          onTimeOut={() => getSingleApplication(application.id)}
                        />
                      )}

                    {!!application.writtenExamStarted && (
                      <ExamStarted
                        applicationId={application.id}
                        getSingleApplication={getSingleApplication}
                      />
                    )}
                    {!!application.writtenExamStarted &&
                      +(application.timeLeftToEndWritten || 0) +
                        (+(application.examAdditionalMinutes || 0) +
                          +(application.applicantAdditionalMinutes || 0)) *
                          1000 *
                          60 -
                        +(application.timeEndedFromEndOfWritten || 0) >
                        0 && (
                        <CountDownTimer
                          counterType="small"
                          defaultLeftDifferent={
                            +(application.timeLeftToEndWritten || 0) +
                            (+(application.examAdditionalMinutes || 0) +
                              +(application.applicantAdditionalMinutes || 0)) *
                              1000 *
                              60 -
                            +(application.timeEndedFromEndOfWritten || 0)
                          }
                          onTimeOut={() => getSingleApplication(application.id)}
                        />
                      )}
                  </div>
                )}

              {!!application.writtenExamSessionId &&
                !application.isOralExamCanceled && (
                  <div className="text-center d-flex flex-column align-items-center">
                    {!!application.timeLeftToStartOral &&
                      !!application.oralVenue &&
                      !application.oralExamStarted && (
                        <small style={{ fontSize: "10px" }}>
                          Oral exam scheduled{" "}
                          <span className="text-primary text-capitalize">
                            @{application.oralVenue.toLowerCase()}{" "}
                          </span>
                        </small>
                      )}

                    {!!application.timeLeftToStartOral &&
                      !application.oralExamStarted && (
                        <CountDownTimer
                          counterType="small"
                          defaultLeftDifferent={
                            application.timeLeftToStartOral || 0
                          }
                          onTimeOut={() => getSingleApplication(application.id)}
                        />
                      )}

                    {!!application.oralExamStarted && (
                      <span className="badge badge-light">
                        <small className="badge badge-pill">
                          <FiberManualRecordIcon className="text-danger" />
                          Oral exam started
                        </small>
                      </span>
                    )}
                  </div>
                )}
            </div>
          </div>
        </CardContent>
      </Card>

      {showReason && (
        <Dialog
          maxWidth="xs"
          fullWidth
          open={showReason}
          onClose={() => setShowReason(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            <IconButton
              onClick={() => {
                setShowReason(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
              aria-label="close reason dialog"
            >
              <span className="material-icons" aria-hidden="true">
                close
              </span>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              InputProps={{
                readOnly: true,
              }}
              className="mt-4 bg-light"
              fullWidth
              label="Reason for rejection"
              placeholder="Reason for rejection"
              value={application.reason || " "}
              multiline
              minRows={4}
            />
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      )}

      {showCheatedReason && (
        <Dialog
          maxWidth="xs"
          fullWidth
          open={showCheatedReason}
          onClose={() => setShowCheatedReason(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => {
                setShowCheatedReason(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <span className="material-icons">close</span>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              InputProps={{
                readOnly: true,
              }}
              className="mt-4 bg-light"
              fullWidth
              label="Reason"
              placeholder="Reason"
              value={application.markedAsCheatedReason || " "}
              multiline
              minRows={4}
            />
          </DialogContent>
          <DialogActions className=""></DialogActions>
        </Dialog>
      )}

      {showLastStatusMessage && (
        <Dialog
          maxWidth="xs"
          fullWidth
          open={showLastStatusMessage}
          onClose={() => setShowLastStatusMessage(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>
            <IconButton
              onClick={() => {
                setShowLastStatusMessage(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
              aria-label="close dialog"
            >
              <span className="material-icons" aria-hidden="true">
                close
              </span>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              InputProps={{
                readOnly: true,
              }}
              className="mt-4 bg-light"
              fullWidth
              label="Reason for cancellation"
              placeholder="Reason for cancellation"
              value={application.lastStatusMessage || " "}
              multiline
              minRows={4}
            />
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      )}

      {showAdvertisementDetails && (
        <AdvertisementDetails
          showDialog={showAdvertisementDetails}
          setShowDialog={setShowAdvertisementDetails}
          advertisement={application}
        />
      )}

      {doPsychometricTest && (
        <PsychometricTest
          showDialog={doPsychometricTest}
          setShowDialog={setDoPsychometricTest}
          advertisement={application}
          closeAdvertisement={() => {}}
        />
      )}

      {showAllCandidatesShortlistedDialog && (
        <AllCandidatesShortlistedDialog
          showDialog={showAllCandidatesShortlistedDialog}
          setShowDialog={setShowAllCandidatesShortlistedDialog}
          application={application}
        />
      )}

      {showAllCandidatesOnWrittenDialog && (
        <AllCandidatesOnWrittenDialog
          showDialog={showAllCandidatesOnWrittenDialog}
          setShowDialog={setShowAllCandidatesOnWrittenDialog}
          application={application}
          setShowWrittenExamDetail={setShowWrittenExamDetail}
        />
      )}

      {showAllCandidatesOnOralDialog && (
        <AllCandidatesOnOralDialog
          showDialog={showAllCandidatesOnOralDialog}
          setShowDialog={setShowAllCandidatesOnOralDialog}
          application={application}
          setShowOralExamDetail={setShowOralExamDetail}
        />
      )}

      {showAllCandidatesResultsDialog && (
        <AllCandidatesResultsDialog
          showDialog={showAllCandidatesResultsDialog}
          setShowDialog={setShowAllCandidatesResultsDialog}
          application={application}
        />
      )}

      {showAllCandidatesOnWaitingDialog && (
        <AllCandidatesOnWaitingDialog
          showDialog={showAllCandidatesOnWaitingDialog}
          setShowDialog={setShowAllCandidatesOnWaitingDialog}
          application={application}
        />
      )}

      {showAppealPreviewDialog && (
        <AppealPreviewDialog
          showDialog={showAppealPreviewDialog}
          setShowDialog={setShowAppealPreviewDialog}
          appealId={appealIdToShow}
          application={application}
        />
      )}

      {showWrittenExamDetail && (
        <WrittenExamDetailDialog
          showDialog={showWrittenExamDetail}
          setShowDialog={setShowWrittenExamDetail}
          application={application}
        />
      )}

      {showOralExamDetail && (
        <OralExamDetailDialog
          showDialog={showOralExamDetail}
          setShowDialog={setShowOralExamDetail}
          application={application}
        />
      )}
      {/* {showAppealForm && (
        <AppealForm
          showDialog={showAppealForm}
          setShowDialog={setShowAppealForm}
          application={application}
          appealLevelId={appealLevelId}
          appealTypeId={appealTypeId}
        />
      )} */}

      {confirmHideOrUnHideApplication && (
        <ConfirmationDialog
          btnMessage={`${application.isHidden ? "unhide" : "hide"}`}
          confirmationDialog={confirmHideOrUnHideApplication}
          message={
            <>
              Are you sure you want to{" "}
              <strong className="mx-1">
                {application.isHidden ? "unhide" : "hide"}
              </strong>{" "}
              this application?
            </>
          }
          setConfirmationDialog={setConfirmHideOrUnHideApplication}
          onYes={() => {
            hideOrUnhideApplication(
              {
                applicationId: application.id,
                isHidden: !application.isHidden,
              },
              () => setConfirmHideOrUnHideApplication(false)
            );
          }}
        />
      )}

      {showJobOfferDialog && (
        <JobOfferDialog
          showDialog={showJobOfferDialog}
          setShowDialog={setShowJobOfferDialog}
          application={application}
        />
      )}
    </article>
  );
}

const mapStateToProps = ({ user, env, loading }) => ({
  user,
  env,
  loading,
});

export default connect(mapStateToProps, {
  hideOrUnhideApplication,
  getSingleApplication,
})(ApplicationItem);

const ExamStarted = (props) => {
  const { getSingleApplication, applicationId } = props;

  useEffect(() => {
    const reflesher = setInterval(() => {
      getSingleApplication(applicationId);
    }, 60000);

    return () => {
      clearInterval(reflesher);
    };
  }, []);

  return (
    <>
      <span className="badge badge-light">
        <small className="badge badge-pill">
          <FiberManualRecordIcon className="text-danger" />
          Online exam started
        </small>
      </span>
    </>
  );
};
