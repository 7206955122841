import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import {
  getUserCV,
  addOrUpdateUserCV,
} from "../../../../store/profile/actions";
import PreviewPdfUrl from "../../../comm/PreviewPdfUrl";
import EditIcon from "@mui/icons-material/Edit";

const CVUpload = (props) => {
  const { loading, userCV, getUserCV, addOrUpdateUserCV, showEdit } = props;

  const [showDialog, setShowDialog] = useState(false);

  const [file, setFile] = useState(null);
  const handleSubmit = async () => {
    const formData = new FormData();
    file && formData.append("file", file[0]);
    addOrUpdateUserCV(formData, () => setShowDialog(false));
  };

  const [onViewDocument, setOnViewDocument] = useState(false);

  useEffect(() => {
    setFile(null);
    getUserCV();
  }, []);

  return (
    <>
      <div
        className="d-flex mb-2 justify-content-between border rounded elevated align-items-center p-2"
        style={{ textDecoration: "none", color: "inherit" }}
        role="region"
        aria-labelledby="cvHeading"
      >
        <div
          className="d-flex flex-row align-items-center px-1 py-2 cursor-pointer"
          style={{
            borderRadius: "4px",
            height: "100%",
          }}
          role="button"
          tabIndex="0"
          aria-label={!userCV ? "Upload your CV" : "View uploaded CV"}
          onClick={() => {
            setOnViewDocument(true);
            if (!userCV) setShowDialog(true);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              setOnViewDocument(true);
              if (!userCV) setShowDialog(true);
            }
          }}
        >
          {!!userCV && (
            <PictureAsPdfRoundedIcon
              fontSize="large"
              style={{ color: "#fe4066" }}
            />
          )}
          {!userCV && <FileUploadIcon fontSize="large" />}
          <div className="ml-2 px-3">
            <span id="cvHeading">
              {!userCV ? "Upload your CV" : "View uploaded CV"}
            </span>
            <div style={{ fontSize: "10px" }} className="font-weight-bold">
              PDF
            </div>
          </div>
        </div>

        {!!userCV && !!showEdit && (
          <div>
            <IconButton
              size="small"
              onClick={() => {
                setShowDialog(true);
              }}
              aria-label="Edit your resume"
            >
              <EditIcon color="primary" />
            </IconButton>
          </div>
        )}
      </div>

      <Dialog
        onClose={() => {
          setShowDialog(false);
          setOnViewDocument(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="overline" display="block">
            <span className="text-dark">
              {userCV ? "Update" : "Upload"} your CV
            </span>
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowDialog(false);
              setOnViewDocument(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <div className="px-md-4 mt-2">
            <form>
              <div className="custom-file mt-3">
                <input
                  disabled={loading}
                  type="file"
                  className="custom-file-input"
                  id="educationFile"
                  accept="application/pdf"
                  onClick={(e) => {
                    e.currentTarget.value = null;
                    setFile(null);
                  }}
                  onChange={(e) => {
                    setFile(e.target.files);
                  }}
                  aria-describedby="fileHelp"
                />
                <label className="custom-file-label" htmlFor="educationFile">
                  {file ? file[0].name : "Upload your CV"}
                </label>
                <small id="fileHelp" className="form-text text-muted">
                  Please upload your CV in PDF format.
                </small>
              </div>
            </form>
          </div>
        </DialogContent>
        <DialogActions className="d-flex flex-row align-items-center justify-content-center pb-4">
          <Button
            variant="contained"
            type="button"
            className={`px-5`}
            disabled={loading || !file}
            onClick={handleSubmit}
            aria-label="Submit your resume"
          >
            <span aria-hidden="true">Upload</span>
          </Button>
        </DialogActions>
      </Dialog>

      {onViewDocument && !!userCV && (
        <PreviewPdfUrl
          showDocument={onViewDocument}
          setShowDocument={() => setOnViewDocument(false)}
          document={{
            name: `CV`,
            url: process.env.REACT_APP_FILES_URL + "/preview/" + userCV,
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading, userCV }) => ({
  user,
  loading,
  userCV,
});

export default connect(mapStateToProps, { getUserCV, addOrUpdateUserCV })(
  CVUpload
);
