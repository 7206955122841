import axios from "../../app/utils/axios-middleware";
import { showSuccess, showError } from "../../app/toastify";

import TYPES from "./action-types";
import COM_TYPES from "../comm/action-types";
const types = { ...COM_TYPES, ...TYPES };

export const getAdvertisements = (open = "") => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({ type: types.SET_ADVERTISEMENTS, data: [] });

      let advertisements;

      if (!!open) {
        const { data } = await axios.get(
          `/api/recruitment/open-advertisements`
        );

        advertisements = data;
      } else {
        const { data } = await axios.get(`/api/recruitment/advertisements`);
        advertisements = data;
      }

      dispatch({ type: types.SET_ADVERTISEMENTS, data: advertisements });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getRequisitionQualifications = (requisitionId, userId = 0) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({
        type: types.SET_REQUISITION_QUALIFICATIONS,
        data: [],
      });

      const { data } = await axios.get(
        `/api/recruitment/requisition-qualifications/${requisitionId}/${userId}`
      );

      dispatch({
        type: types.SET_REQUISITION_QUALIFICATIONS,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getRequisitionCertificates = (requisitionId, userId = 0) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({
        type: types.SET_REQUISITION_CERTIFICATES,
        data: [],
      });

      const { data } = await axios.get(
        `/api/recruitment/requisition-certificates/${requisitionId}/${userId}`
      );

      dispatch({
        type: types.SET_REQUISITION_CERTIFICATES,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getRequisitionCompetencies = (requisitionId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({
        type: types.SET_REQUISITION_COMPETENCIES,
        data: [],
      });
      const { data } = await axios.get(
        `/api/recruitment/requisition-competencies/${requisitionId}`
      );

      dispatch({
        type: types.SET_REQUISITION_COMPETENCIES,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getApplications = (showHidden, setAllHidden) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });
      dispatch({ type: types.SET_APPLICATIONS, data: [] });
      const { data } = await axios.get(
        "/api/recruitment/applications/" + showHidden
      );

      dispatch({ type: types.SET_APPLICATIONS, data: data.applications });

      setAllHidden(data.allHidden);

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getSingleApplication = (applicationId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/recruitment/single-application/" + applicationId
      );

      dispatch({ type: types.UPDATE_APPLICATION, data });
    } catch (error) {
      showError(error);
    }
  };
};

export const getWaitingList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(`/api/recruitment/waiting-list`);

      dispatch({ type: types.SET_WAITING_LIST, data });
      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getWaitingListDetails = (applicationId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        `/api/recruitment/waiting-list-details/` + applicationId
      );

      dispatch({ type: types.SET_WAITING_LIST_DETAILS, data });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const doApply = (formData, cb, showMsg = true) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        "/api/recruitment/applications",
        formData
      );

      dispatch({ type: types.UPDATE_ADVERTISEMENT, data });

      !!showMsg && showSuccess("Application submitted successfully");

      !!showMsg && dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      cb();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getAppeal = (appealId, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.SET_APPEAL, data: {} });

      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.get(
        `/api/recruitment/signle-application-appeal/${appealId}`
      );

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({ type: types.SET_APPEAL, data });
    } catch (error) {
      showError(error);
      onClose();
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    }
  };
};

export const submitAppeal = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(`/api/recruitment/appeals`, formData);

      showSuccess("Submitted successfully");

      dispatch({ type: types.END_LOADING });

      dispatch({ type: types.UPDATE_APPLICATION, data: data.application });
      dispatch({ type: types.SET_APPEAL, data: data.appeal });

      onClose();
    } catch (error) {
      showError(error);
      dispatch({ type: types.END_LOADING });
    }
  };
};

export const acceptAppealDecision = (appealId, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        `/api/recruitment/accept-appeal-decision/${appealId}`
      );

      showSuccess("Appeal decision accepted");

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });

      dispatch({ type: types.UPDATE_APPLICATION, data: data.application });
      dispatch({ type: types.SET_APPEAL, data: data.appeal });

      onClose();
    } catch (error) {
      showError(error);
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    }
  };
};

export const hideOrUnhideApplication = (
  { applicationId, isHidden },
  onClose
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        `/api/recruitment/hide-or-unhide-application`,
        {
          applicationId,
          isHidden,
        }
      );

      showSuccess(data);

      onClose();

      dispatch({ type: types.DELETE_APPLICATION, id: applicationId });

      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const respondToJobOffer = (formData, onClose) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        `/api/recruitment/respond-to-job-offer`,
        formData
      );

      dispatch({ type: types.END_LOADING });

      onClose();

      dispatch({ type: types.UPDATE_APPLICATION, data });

      showSuccess(
        `Job offer ${
          formData.statusId === 2 ? "accepted" : "rejected"
        } successfully`
      );
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
export const getAnnouncements = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_WAIT });
      const { data } = await axios.get(`/api/recruitment/announcements`);

      dispatch({ type: types.SET_ANNOUNCEMENTS, data });
      dispatch({ type: types.END_WAIT });
    } catch (error) {
      dispatch({ type: types.END_WAIT });
      showError(error);
    }
  };
};

export const getAdvertisementPsychometricLanguages = (requisitionId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/recruitment/advertisement-psychometric-languages/" + requisitionId
      );

      dispatch({
        type: types.SET_ADVERTISEMENT_PSYCHOMETRIC_LANGUAGES,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};

export const getAdvertisementPsychometricSubDomains = (requisitionId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        "/api/recruitment/advertisement-knowledge-subdomains/" + requisitionId
      );

      dispatch({
        type: types.SET_ADVERTISEMENT_KNOWLEDGE_SUBDOMAINS,
        data,
      });
    } catch (error) {
      showError(error);
    }
  };
};
