import React from "react";
// import Breadcrumb from "./Breadcrumb";
import { Link, useLocation } from "react-router-dom";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import FolderSpecialRoundedIcon from "@mui/icons-material/FolderSpecialRounded";
import RuleIcon from "@mui/icons-material/Rule";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 10,
    top: 2,
    border: `2px solid #fff`,
    padding: "0 8px",
  },
}));

const MenuBar = () => {
  const location = useLocation();

  return (
    <nav role="navigation">
      <div className="d-flex flex-row align-items-center justify-content-between modules-menu">
        <div className="d-flex align-items-center flex-nowrap ">
          <Link
            className={`py-0 btn btn-link text-truncate btn-sm ${
              location.pathname.includes(`/applicant/advertisements`)
                ? "active"
                : ""
            }`}
            to="/applicant/advertisements"
          >
            <span className="d-inline-flex align-items-center">
              <WorkRoundedIcon className="mr-1  d-none d-sm-inline" />
              <span>Advertisements</span>
            </span>
          </Link>

          <Link
            className={`py-0 btn text-truncate btn-link btn-sm ${
              location.pathname.includes(`/applicant/applications`)
                ? "active"
                : ""
            }`}
            to="/applicant/applications"
          >
            <span className="d-inline-flex align-items-center">
              <FolderSpecialRoundedIcon className="mr-1 d-none d-sm-inline" />
              <span>My Applications</span>
            </span>
          </Link>

          {/* <StyledBadge badgeContent={11} color="error" max={10}>
            <Link
              className={`py-0 btn text-truncate btn-link btn-sm ${
                location.pathname.includes(`/applicant/exams`) ? "active" : ""
              }`}
              to="/applicant/exams"
            >
              <span className="d-inline-flex align-items-center">
                <RuleIcon className="mr-1  d-none d-sm-inline" />
                <span>Exams</span>
              </span>
            </Link>
          </StyledBadge> */}
        </div>
      </div>
      <div style={{ marginTop: "0px" }}>
        <div style={{ backgroundColor: "#065c84", height: "2px" }}></div>
        <div style={{ backgroundColor: "#199e05", height: "1px" }}></div>
        <div style={{ backgroundColor: "#e5cb05", height: "2px" }}></div>
      </div>
    </nav>
  );
};

export default MenuBar;
