import { defaultState } from "./state";

import {
  shuffle,
  shuffleQuestions,
  sortQuestionByReference,
} from "../../app/utils";

import TYPES from "./action-types";
import COM_TYPES from "../comm/action-types";
const types = { ...COM_TYPES, ...TYPES };

const psychometricLaguages = (
  state = defaultState.psychometricLaguages,
  action
) => {
  switch (action.type) {
    case types.SET_PSYCHOMETRIC_LANGUAGES:
      return action.data;

    case types.LOGOUT:
      return defaultState.psychometricLaguages;
    default:
      return state;
  }
};

const psychometricSession = (
  state = defaultState.psychometricSession,
  action
) => {
  switch (action.type) {
    case types.SET_PSYCHOMETRIC_SESSION:
      return action.data;

    case types.LOGOUT:
      return defaultState.psychometricSession;
    default:
      return state;
  }
};

const psychometricQuestions = (
  state = defaultState.psychometricQuestions,
  action
) => {
  switch (action.type) {
    case types.SET_PSYCHOMETRIC_QUESTIONS:
      return action.data;

    case types.UPDATE_PSYCHOMETRIC_QUESTION: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => +id === +action.data.id);

      if (index >= 0) {
        const options = [];
        tmpState[index].options.forEach((o) => {
          options.push(
            action?.data?.options?.find(
              ({ optionNumber }) => optionNumber === o.optionNumber
            ) || o
          );
        });

        tmpState[index] = { ...action.data, options };
      }

      return tmpState;
    }

    case types.LOGOUT:
      return defaultState.psychometricQuestions;
    default:
      return state;
  }
};

const currentPSYQuestionNo = (
  state = defaultState.currentPSYQuestionNo,
  action
) => {
  switch (action.type) {
    case types.SET_CURRENT_PSY_QUETION_NO:
      return action.no;

    case types.LOGOUT:
      return defaultState.currentPSYQuestionNo;
    default:
      return state;
  }
};

const questionTime = (state = defaultState.questionTime, action) => {
  switch (action.type) {
    case types.SET_QUETION_TIME:
      return action.questionTime;

    case types.LOGOUT:
      return defaultState.questionTime;
    default:
      return state;
  }
};

const psychometricSessionLogs = (
  state = defaultState.psychometricSessionLogs,
  action
) => {
  switch (action.type) {
    case types.SET_PSYCHOMETRIC_SESSION_LOGS:
      return action.data;

    case types.LOGOUT:
      return defaultState.psychometricSessionLogs;
    default:
      return state;
  }
};

const psychometricInstructions = (
  state = defaultState.psychometricInstructions,
  action
) => {
  switch (action.type) {
    case types.SET_PSYCHOMETRIC_INSTRUCTIONS:
      return action.data;

    case types.LOGOUT:
      return defaultState.psychometricInstructions;
    default:
      return state;
  }
};

const feedbackQuestions = (state = defaultState.feedbackQuestions, action) => {
  switch (action.type) {
    case types.SET_FEEDBACK_QUESTIONS:
      return action.data;

    case types.LOGOUT:
      return defaultState.feedbackQuestions;
    default:
      return state;
  }
};

export default {
  psychometricLaguages,
  psychometricSession,
  psychometricQuestions,
  currentPSYQuestionNo,
  questionTime,
  psychometricSessionLogs,
  psychometricInstructions,
  feedbackQuestions,
};
