const types = {
  SET_PSYCHOMETRIC_LANGUAGES: "SET_PSYCHOMETRIC_LANGUAGES",
  SET_PSYCHOMETRIC_SESSION: "SET_PSYCHOMETRIC_SESSION",
  SET_PSYCHOMETRIC_QUESTIONS: "SET_PSYCHOMETRIC_QUESTIONS",
  UPDATE_PSYCHOMETRIC_QUESTION: "UPDATE_PSYCHOMETRIC_QUESTION",
  SET_CURRENT_PSY_QUETION_NO: "SET_CURRENT_PSY_QUETION_NO",
  SET_QUETION_TIME: "SET_QUETION_TIME",
  SET_PSYCHOMETRIC_SESSION_LOGS: "SET_PSYCHOMETRIC_SESSION_LOGS",
  SET_PSYCHOMETRIC_INSTRUCTIONS: "SET_PSYCHOMETRIC_INSTRUCTIONS",
  SET_FEEDBACK_QUESTIONS: "SET_FEEDBACK_QUESTIONS",
};

export default types;
