import { combineReducers } from "redux";

import commonReducers from "./comm/reducers";
import applicantReducers from "./applicant/reducers";
import profileReducers from "./profile/reducers";
import examReducers from "./exam/reducers";
import psychometricReducers from "./psychometric/reducers";

export default combineReducers({
  //---- COMMON ----//
  ...commonReducers,
  ...applicantReducers,
  ...profileReducers,
  ...examReducers,
  ...psychometricReducers,
});
