import React, { useEffect, useState } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Alert,
} from "@mui/material";
import { Label, Padding } from "@mui/icons-material";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { getPsychometricInstructions } from "../../../../store/psychometric/actions";

const LanguageSelection = ({
  psychometricLaguages,
  psychometricSession,
  selectedLanguage,
  setSelectedLanguage,
  getPsychometricInstructions,
}) => {
  useEffect(() => {
    getPsychometricInstructions();
  }, []);
  return (
    <>
      <Typography className="text-primary  text-center mt-5">
        Select your preferred language for the test.
      </Typography>
      <div className="d-flex align-items-center flex-column">
        <RadioGroup style={{ fontSize: "8px" }}>
          {psychometricLaguages
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((language, index) => (
              <FormControlLabel
                key={index}
                value={language.name}
                control={
                  <Radio
                    disabled={
                      !!psychometricSession &&
                      psychometricSession?.preferredLanguageId !== language.id
                    }
                    checked={
                      (selectedLanguage?.id ||
                        psychometricSession?.preferredLanguageId) ===
                      language.id
                    }
                    onChange={(e) => {
                      setSelectedLanguage(language);
                    }}
                  />
                }
                label={language.name}
              />
            ))}
        </RadioGroup>
      </div>
    </>
  );
};

const mapStateToProps = ({ psychometricLaguages, psychometricSession }) => ({
  psychometricLaguages,
  psychometricSession,
});

export default connect(mapStateToProps, { getPsychometricInstructions })(
  LanguageSelection
);
