import React, { useEffect, useState } from "react";
import {
  Grid,
  Autocomplete,
  TextField,
  Chip,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Alert,
  Box,
  Stepper,
  Step,
  StepLabel,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { connect, useDispatch } from "react-redux";
import ConfirmationDialog from "../../../comm/ConfirmationDialog";
import {
  startPsychometricTest,
  goToQuestionNo,
  handlePSYCheatings,
} from "../../../../store/psychometric/actions";

import { useTranslation } from "react-i18next";
import QuestionItem from "./QuestionItem";
import { showError } from "../../../toastify";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function reshuffleArray(arr) {
  const newArr = arr.slice();

  for (let i = newArr.length - 1; i > 0; i--) {
    const rand = Math.floor(Math.random() * (i + 1));
    [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
  }
  return newArr;
}

const DoTest = (props) => {
  const {
    user,
    loading,
    psychometricSession,
    startPsychometricTest,
    psychometricQuestions,
    currentPSYQuestionNo,
    goToQuestionNo,
    onSubmit,
    setOnSubmit,
    isFullScreen,
    setIsFullScreen,
    handlePSYCheatings,
    exitFullScreen,
    startTest,
    psychometricSessionLogs,
    translate,
  } = props;

  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedQuestionScore, setSelectedQuestionScore] = useState(null);
  const [confirmStartTest, setConfirmStartTest] = useState(false);
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const handleLeaveBrowser = () => {
    handleAllCheatingAttempt("TRIED_TO_LEAVE_BROWSER");
  };

  const handleLeaveFullscreen = (e) => {
    if (!document.fullscreenElement) {
      handleAllCheatingAttempt("TRIED_TO_MINIMIZE");
      setIsFullScreen(false);
    } else setIsFullScreen(true);
  };

  const handleReloadTab = (e) => {
    e.preventDefault();
    handleAllCheatingAttempt("TRIED_TO_RELOAD");

    return (e.returnValue = "Are you sure you want to exit?");
  };

  useEffect(() => {
    window.addEventListener("blur", handleLeaveBrowser);
    window.addEventListener("fullscreenchange", handleLeaveFullscreen);
    window.addEventListener("beforeunload", handleReloadTab);

    return () => {
      window.removeEventListener("blur", handleLeaveBrowser);
      window.removeEventListener("fullscreenchange", handleLeaveFullscreen);
      window.removeEventListener("beforeunload", handleReloadTab);
      exitFullScreen();
      dispatch({ type: "SET_PSYCHOMETRIC_QUESTIONS", data: [] });
      goToQuestionNo(0);
    };
  }, []);

  useEffect(() => {
    const index = psychometricQuestions.findIndex((q) => !q.answered);
    if (currentPSYQuestionNo === 0) {
      if (index >= 0) goToQuestionNo(index);
      else {
        goToQuestionNo(0);
        setOnSubmit(true);
      }
    }
  }, []);

  const handleAllCheatingAttempt = (eventType) => {
    if (!onSubmit)
      handlePSYCheatings(
        { applicantSessionId: psychometricSession?.id, eventType },
        exitFullScreen
      );
  };

  return (
    <>
      <QuestionItem
        psychometricSession={psychometricSession}
        currentPSYQuestionNo={currentPSYQuestionNo}
        question={psychometricQuestions[currentPSYQuestionNo]}
        noOfQuestions={psychometricQuestions.length}
        reshuffledOptions={reshuffleArray(
          psychometricQuestions[currentPSYQuestionNo].options
        )}
        onSubmit={onSubmit}
        setOnSubmit={setOnSubmit}
        exitFullScreen={exitFullScreen}
        translate={translate}
      />

      {/* <Alert severity="info" icon={false}>
        <div className="row">
          <div className="col">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography component="span">Accordion 1</Typography>
              </AccordionSummary>
              <AccordionDetails>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                eget.
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </Alert> */}

      {/* <Box sx={{ width: "100%" }}>
        <Stepper>
          {psychometricSessionLogs.map((log) => (
            <Step key={log.id}>
              <StepLabel>{log.action}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box> */}
    </>
  );
};

const mapStateToProps = ({
  user,
  loading,
  psychometricSession,
  psychometricQuestions,
  currentPSYQuestionNo,
  psychometricSessionLogs,
}) => ({
  user,
  loading,
  psychometricSession,
  psychometricQuestions,
  currentPSYQuestionNo,
  psychometricSessionLogs,
});

export default connect(mapStateToProps, {
  startPsychometricTest,
  goToQuestionNo,
  handlePSYCheatings,
})(DoTest);
