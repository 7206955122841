import React, { useState, useEffect } from "react";
import colors from "../../../utils/colors";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";

import CountDownTimer from "./CountDownTimer";

const ExamIntroduction = (props) => {
  const { loading, application, getSingleApplication, onStartExam } = props;

  const [examKey, setExamKey] = useState(
    application && !application.enableExamKey ? "123" : ""
  );

  const history = useHistory();

  const [showConfirmStartExam, setShowConfirmStartExam] = useState(false);

  useEffect(() => {
    document.title = "Examination Portal - Instructions";
  }, []);

  useEffect(() => {
    getSingleApplication();
    setExamKey( application && !application.enableExamKey ? "123" : "");
  }, []);

  return (
    <div className="container-fluid">
      <Card className={`border rounded bg-light`} elevation={1}>
        <CardHeader
          className="pb-0"
          title={
            <Typography
              sx={{ fontSize: 16, color: colors.blueLogo }}
              variant="h6"
            >
              {application?.positionName}
            </Typography>
          }
          action={
            <Button
              disabled={loading}
              color="primary"
              size="small"
              variant="outlined"
              type="button"
              onClick={() => history.push("/applicant/applications")}
              aria-label="Close exam"
            >
              <span aria-hidden="true">Close</span>
            </Button>
          }
        />
        <CardContent className="pt-0">
          <Typography variant="h6" component="div" sx={{ fontSize: 13 }}>
            <div className=" d-flex flex-row flex-wrap justify-content-center px-3 py-2 mt-3">
              <div className="d-flex flex-column px-3">
                <span style={{ fontSize: "14px" }}>Date:</span>
                <span
                  className="font-weight-bold"
                  style={{ fontSize: "16px", color: colors.blueLogo }}
                >
                  {moment(application?.writtenExamDate).format("ll")}
                </span>
              </div>

              <div className="d-flex flex-column px-3">
                <span style={{ fontSize: "14px" }}>Total marks:</span>
                <span
                  className="font-weight-bold"
                  style={{ fontSize: "16px", color: colors.blueLogo }}
                >
                  {application?.writtenTotalMarks}
                </span>
              </div>
              <div className="d-flex flex-column px-3">
                <span style={{ fontSize: "14px" }}>Duration:</span>
                <span>
                  <CountDownTimer
                    counterType="small"
                    defaultLeftDifferent={
                      +(application.timeLeftToEndWritten || 0) +
                      (+(application.examAdditionalMinutes || 0) +
                        +(application.applicantAdditionalMinutes || 0)) *
                        1000 *
                        60 -
                      +(application.timeEndedFromEndOfWritten || 0)
                    }
                    onTimeOut={() => {}}
                  />
                </span>
              </div>
            </div>
          </Typography>
          <div className="border p-3 mt-3 bg-white">
            <div className="d-flex flex-column align-items-center justify-content-center py-3">
              <div className="mt-1">
                <Typography variant="h5" className="mb-2">
                  Instructions
                </Typography>
                <Typography variant="body1" className="mb-3">
                  You are not permitted to have on your desk or on your person
                  any unauthorized material during this examination.
                  <br />
                  This includes:
                </Typography>
                <ul>
                  <li>
                    <Typography variant="body1">Mobile phones</Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      Smart watches and bands
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      Electronic devices (including additional monitors,
                      earphones, headphones etc).
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      Headgear (hats, hoodies excluding religious headgears)
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      Draft paper, Textbooks, Notes
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="body1">
                      Once you <strong>Click Start Exam</strong>, you will not
                      be allowed to minimize or leave exam window before you
                      submit exam.
                    </Typography>
                  </li>
                </ul>
                <div className="mt-4 text-right">
                  <Button
                    disabled={loading || showConfirmStartExam}
                    color="success"
                    variant="contained"
                    type="button"
                    onClick={() => setShowConfirmStartExam(true)}
                  >
                    Start Exam
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {showConfirmStartExam && (
        <Dialog
          maxWidth="xs"
          fullWidth
          open={showConfirmStartExam}
          onClose={() => setShowConfirmStartExam(false)}
        >
          <DialogTitle className="d-flex align-items-center text-danger font-weight-bold">
            <span className="material-icons mr-2 font-weight-bold">
              report_gmailerrorred
            </span>{" "}
            Attention
          </DialogTitle>
          <DialogContent>
            <Alert icon={false} severity="error">
              Your exam will be locked if you try to leave fullscreen, close,
              minimize or reload the exam window.
            </Alert>
            <TextField
              disabled={application && !application.enableExamKey}
              autoFocus
              label="Enter exam key from your invigilator"
              placeholder="Enter exam key from your invigilator"
              value={examKey}
              className="w-100 mt-2 bg-white"
              onChange={(e) => setExamKey(e.target.value)}
            />
          </DialogContent>
          <DialogActions className="d-flex justify-content-center pb-3">
            <Button
              disabled={loading || !examKey}
              color="success"
              variant="contained"
              onClick={() => {
                setShowConfirmStartExam(false);
                onStartExam(examKey);
              }}
              aria-label={`Confirm exam instructions to start exam`}
            >
              <span aria-hidden="true">Yes, I understand</span>
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

const mapStateToProps = ({ user, loading }) => ({
  user,
  loading,
});

export default connect(mapStateToProps, {})(ExamIntroduction);
