import { defaultState } from "./state";

import TYPES from "./action-types";
import COM_TYPES from "../comm/action-types";
const types = { ...COM_TYPES, ...TYPES };

const advertisements = (state = defaultState.advertisements, action) => {
  switch (action.type) {
    case types.SET_ADVERTISEMENTS:
      return action.data;

    case types.DELETE_ADVERTISEMENT: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.id);
      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.UPDATE_ADVERTISEMENT: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;

      return tmpState;
    }

    case types.CLEAN_STATE:
      return [...state].filter(({ isInternal }) => isInternal === false);

    default:
      return state;
  }
};

const requisitionQualifications = (
  state = defaultState.requisitionQualifications,
  action
) => {
  switch (action.type) {
    case types.SET_REQUISITION_QUALIFICATIONS:
      return action.data;

    case types.UPDATE_REQUISITION_QUALIFICATION: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.requisitionQualifications;

    default:
      return state;
  }
};

const requisitionCertificates = (
  state = defaultState.requisitionCertificates,
  action
) => {
  switch (action.type) {
    case types.SET_REQUISITION_CERTIFICATES:
      return action.data;

    case types.UPDATE_REQUISITION_CERTIFICATE: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.requisitionCertificates;

    default:
      return state;
  }
};

const requisitionCompetencies = (
  state = defaultState.requisitionCompetencies,
  action
) => {
  switch (action.type) {
    case types.SET_REQUISITION_COMPETENCIES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.requisitionCompetencies;

    default:
      return state;
  }
};

const applications = (state = defaultState.applications, action) => {
  switch (action.type) {
    case types.SET_APPLICATIONS:
      return action.data;

    case types.DELETE_APPLICATION: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.id);
      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.UPDATE_APPLICATION: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.data.id);

      if (index >= 0) tmpState[index] = action.data;

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.applications;

    default:
      return state;
  }
};

const waitingList = (state = defaultState.waitingList, action) => {
  switch (action.type) {
    case types.SET_WAITING_LIST:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.waitingList;

    default:
      return state;
  }
};

const waitingListDetails = (
  state = defaultState.waitingListDetails,
  action
) => {
  switch (action.type) {
    case types.SET_WAITING_LIST_DETAILS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.waitingListDetails;

    default:
      return state;
  }
};

const selectedAdvertisement = (
  state = defaultState.selectedAdvertisement,
  action
) => {
  switch (action.type) {
    case types.SET_SELECTED_ADVERTISEMENT:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.selectedAdvertisement;

    default:
      return state;
  }
};

const appeal = (state = defaultState.appeal, action) => {
  switch (action.type) {
    case types.SET_APPEAL:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.appeal;

    default:
      return state;
  }
};
const announcements = (state = defaultState.announcements, action) => {
  switch (action.type) {
    case types.SET_ANNOUNCEMENTS:
      return action.data;

    case types.DELETE_ANNOUNCEMENT: {
      const tmpState = [...state];
      const index = tmpState.findIndex(({ id }) => id === action.id);
      if (index >= 0) tmpState.splice(index, 1);

      return tmpState;
    }

    case types.CLEAN_STATE:
      return defaultState.announcements;

    default:
      return state;
  }
};

const advertisementPsychometricLanguages = (
  advertisementPsychometricLanguagesState = defaultState.advertisementPsychometricLanguages,
  action
) => {
  switch (action.type) {
    case types.SET_ADVERTISEMENT_PSYCHOMETRIC_LANGUAGES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.advertisementPsychometricLanguages;

    default:
      return advertisementPsychometricLanguagesState;
  }
};

const advertisementKnowledgeSubDomains = (
  advertisementKnowledgeSubDomainsState = defaultState.advertisementKnowledgeSubDomains,
  action
) => {
  switch (action.type) {
    case types.SET_ADVERTISEMENT_KNOWLEDGE_SUBDOMAINS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.advertisementKnowledgeSubDomains;

    default:
      return advertisementKnowledgeSubDomainsState;
  }
};

export default {
  advertisements,
  requisitionQualifications,
  requisitionCertificates,
  requisitionCompetencies,
  applications,
  waitingList,
  waitingListDetails,
  selectedAdvertisement,
  appeal,
  announcements,
  advertisementPsychometricLanguages,
  advertisementKnowledgeSubDomains,
};
