import React from "react";

import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Chip,
  Divider,
  Checkbox,
  FormControlLabel,
  FormControl,
  RadioGroup,
} from "@mui/material";
import {
  CheckCircleOutline as CheckIcon,
  ErrorOutline as ErrorIcon,
  ComputerOutlined as ComputerIcon,
  NetworkCheckOutlined as NetworkIcon,
  CameraAltOutlined as CameraIcon,
  SecurityOutlined as SecurityIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

const instructions = {
  applicantInstructions: [
    `This psychometric test is an integral part of the shortlisting process and does not replace the official online written exam.`,
    `Read carefully the required competencies, psychometric domains, and languages for the position you want to apply for.`,
    `This psychometric test is an integral part of the shortlisting process and does not replace the official online written exam.`,
  ],
};

// const RuleSection = ({ title, icon, children }) => (
//   <Box
//     sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "12px", p: 1 }}
//   >
//     <Box
//       display="flex"
//       alignItems="center"
//       sx={{ mb: 1, padding: "0 0 0 10px" }}
//     >
//       {icon}
//       <Typography
//         variant="subtitle1"
//         sx={{ ml: 2, fontWeight: "bold", color: "primary.main" }}
//       >
//         {title}
//       </Typography>
//     </Box>
//     {children}
//   </Box>
// );

const ExamRules = ({
  acceptInstructions,
  setAcceptInstructions,
  psychometricLaguages,
  selectedLanguage,
  psychometricInstructions,
  translate,
}) => {
  return (
    <div className="row mt-2 align-items-center justify-content-center">
      <div className="col-12  col-md-6">
        <div
          className={`px-3 border  rounded`}
          style={{
            minHeight: "60vh",
          }}
        >
          <Typography className="text-primary mt-2">
            Technical Requirements
          </Typography>

          <ol>
            {psychometricInstructions
              .filter(({ category }) => category === "TECHNICAL")
              .map((instruction) => (
                <li
                  key={instruction.id}
                  className="  mb-1"
                  style={{ fontSize: "14px" }}
                >
                  {translate(instruction)}
                </li>
              ))}
          </ol>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div
          className={`px-3 border  rounded`}
          style={{
            minHeight: "60vh",
          }}
        >
          <Typography className="text-primary mt-2">
            Applicant Instructions
          </Typography>

          <ol>
            {psychometricInstructions
              .filter(({ category }) => category === "APPLICANT")
              .map((instruction) => (
                <li
                  key={instruction.id}
                  className="  mb-1"
                  style={{ fontSize: "14px" }}
                >
                  {translate(instruction)}
                </li>
              ))}
          </ol>
        </div>
      </div>

      <div className="col-12 text-center  ">
        <FormControl>
          <RadioGroup>
            <FormControlLabel
              className="mb-0"
              control={
                <Checkbox
                  size="small"
                  checked={acceptInstructions}
                  onChange={(e) => setAcceptInstructions(e.target.checked)}
                />
              }
              label="I have read and accepted all of the instructions."
            />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  user,
  loading,
  psychometricSession,
  psychometricInstructions,
}) => ({
  user,
  loading,
  psychometricSession,
  psychometricInstructions,
});

export default connect(mapStateToProps, {})(ExamRules);
