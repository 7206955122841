import { Skeleton } from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAdvertisementPsychometricSubDomains } from "../../../store/applicant/actions";
import ReactPaginate from "react-paginate";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemAvatar,
  Avatar,
} from "@mui/material";

const AdvertisementPsychometricSubDomains = (props) => {
  const {
    requisitionId,
    advertisementKnowledgeSubDomains,
    getAdvertisementPsychometricSubDomains,
  } = props;

  useEffect(
    () => {
      getAdvertisementPsychometricSubDomains(requisitionId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requisitionId]
  );

  const getNo = (idToSearch) =>
    advertisementKnowledgeSubDomains.findIndex(({ id }) => id === idToSearch);

  return (
    <>
      {!!advertisementKnowledgeSubDomains.length && (
        <div className="col-lg-12 mt-3">
          <strong>Psychometric Domains</strong>

          <List
            className="mt-2"
            sx={{
              borderRadius: "8px",
              border: `1px solid #c8c6c6`,
              backgroundColor: "#f9f9f9",
            }}
            dense
          >
            {advertisementKnowledgeSubDomains.map((row, index) => (
              <React.Fragment key={row.id}>
                <ListItem dense>
                  <ListItemAvatar>
                    <Avatar
                      style={{
                        fontSize: "16px",
                        border: "1px solid #c8c6c6",
                      }}
                      className="bg-white text-dark "
                    >
                      {getNo(row.id) + 1}
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={<span>{row.subDomain && row.subDomain.name}</span>}
                    secondary={
                      <>
                        <small className={`font-weight-light`}>
                          {row.subDomain &&
                            row.subDomain.domain &&
                            row.subDomain.domain.name}
                        </small>
                      </>
                    }
                  />
                </ListItem>

                {advertisementKnowledgeSubDomains.length > index + 1 && (
                  <Divider />
                )}
              </React.Fragment>
            ))}
          </List>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ loading, advertisementKnowledgeSubDomains }) => {
  return {
    loading,
    advertisementKnowledgeSubDomains,
  };
};
export default connect(mapStateToProps, {
  getAdvertisementPsychometricSubDomains,
})(AdvertisementPsychometricSubDomains);
