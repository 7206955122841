import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AdvertisementItem from "./AdvertisementItem";
import { connect, useDispatch } from "react-redux";
import AdvertisementQualifications from "./AdvertisementQualifications";
import AdvertisementCertificates from "./AdvertisementCertificates";
import AdvertisementCompetencies from "./AdvertisementCompetencies";
import LoginModal from "../../home/components/LoginModal";
import RegisterModal from "../../home/components/RegisterModal";
import ConfirmationDialog from "../../comm/ConfirmationDialog";
import { doApply } from "../../../store/applicant/actions";
import ApplicationItem from "./ApplicationItem";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AdvertisementPsychometricLanguages from "./AdvertisementPsychometricLanguages";
import AdvertisementPsychometricSubDomains from "./AdvertisementPsychometricSubDomains";

import PsychometricTest from "../psychometric";

// import {
//   getPsychometricLaguages,
//   getPsychometricSession,
// } from "./../../../store/psychometric/actions";

function AdvertisementDetails(props) {
  const {
    loading,
    onWait,
    showDialog,
    setShowDialog,
    advertisement,
    externalAdvert,
    isHome,
    doApply,
    // getPsychometricLaguages,
    // getPsychometricSession,
  } = props;

  const dispatch = useDispatch();

  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [showRegisterDialog, setShowRegisterDialog] = useState(false);

  const [educationQualified, setEducationQualified] = useState(false);
  const [certificateQualified, setCertificateQualified] = useState(false);
  const [confirmApply, setConfirmApply] = useState(false);

  const [doPsychometricTest, setDoPsychometricTest] = useState(false);

  const onClose = () => {
    dispatch({
      type: "SET_SELECTED_ADVERTISEMENT",
      data: null,
    });
    setShowDialog(false);
  };

  useEffect(() => {
    if (isHome)
      dispatch({
        type: "SET_SELECTED_ADVERTISEMENT",
        data: { ...advertisement, externalAdvert },
      });
  }, []);

  // useEffect(() => {
  //   if (!isHome) {
  //     getPsychometricLaguages(
  //       advertisement.advertisementId || advertisement.id
  //     );
  //     getPsychometricSession(advertisement.advertisementId || advertisement.id);
  //   }
  // }, [advertisement]);

  const [activeStep, setActiveStep] = useState(0);

  const [steps, setSteps] = useState([
    "Advertisement Details",
    "Psychometric Test",
  ]);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle id="customized-dialog-title">
          <Typography variant="overline" display="block">
            <span className="text-dark">Advertisement details</span>
            {(!loading || onWait) &&
              (!educationQualified || !certificateQualified) &&
              !isHome &&
              !advertisement.advertisementId && (
                <span
                  className="badge badge-warning ml-1"
                  aria-label={`You missed required ${
                    !educationQualified ? "education" : "certificate"
                  } qualification`}
                >
                  <span aria-hidden="true">You are not qualified!</span>
                </span>
              )}

            {(!loading || onWait) &&
              educationQualified &&
              certificateQualified &&
              !isHome &&
              !advertisement.advertisementId && (
                <span
                  className="badge badge-success ml-1"
                  aria-label={`You are qualified for this position`}
                >
                  <span aria-hidden="true">You are qualified!</span>
                </span>
              )}
          </Typography>
          <IconButton
            aria-label="close advertisement details dialog"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <span className="material-icons">close</span>
          </IconButton>
        </DialogTitle>

        <DialogContent dividers className="px-1 px-sm-3">
          <div
            style={{
              minHeight: "75vh",
            }}
          >
            <div className="card rounded">
              <div
                className="card-header rounded-top p-0"
                style={{ padding: "0.25rem 1.2rem" }}
              >
                {!advertisement.advertisementId ? (
                  <AdvertisementItem
                    roundedValue="rounded-top"
                    advertisement={advertisement}
                    isForView={true}
                  />
                ) : (
                  <ApplicationItem
                    roundedValue="rounded-top"
                    application={advertisement}
                    isForView={true}
                  />
                )}
              </div>

              <div className="card-body">
                <div className="row mt-n3">
                  <div className="col-12 col-md-6 text-left">
                    <small>
                      <Typography
                        variant="body2"
                        component="h2"
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                        role="list"
                      >
                        Exams to be conducted
                      </Typography>{" "}
                      <br />
                      {advertisement.willHavePsychometricTest && (
                        <>
                          <span role="listitem">1:PsychometricTest</span>
                          <br />
                        </>
                      )}
                      {advertisement.willHaveWrittenExam && (
                        <>
                          <span role="listitem">
                            {advertisement.willHavePsychometricTest ? "2" : "1"}
                            :Written
                          </span>
                          <br />
                        </>
                      )}
                      {advertisement.willHaveOralExam && (
                        <span role="listitem">
                          {advertisement.willHavePsychometricTest &&
                          advertisement.willHaveWrittenExam
                            ? "3"
                            : advertisement.willHaveWrittenExam
                            ? "2"
                            : "1"}
                          :Oral
                        </span>
                      )}
                    </small>
                  </div>
                  <div className="col-12 col-md-6 text-right">
                    <Typography
                      variant="body2"
                      component="h2"
                      style={{
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      Reports To
                    </Typography>
                    <br />
                    <span className="text-primary">
                      {advertisement.reportsTo}
                    </span>
                  </div>
                </div>
                {/* Job description */}
                <div className="mt-2">
                  <Typography
                    variant="body2"
                    component="h2"
                    style={{ fontWeight: "bold" }}
                  >
                    Job responsibilities
                  </Typography>
                  <div
                    className="mt-1 p-4 rounded"
                    contentEditable={false} // Set to true if you want it editable
                    style={{
                      whiteSpace: "pre-line",
                      backgroundColor: "#f9f9f9",
                      borderRadius: "8px",
                      border: `1px solid #c8c6c6`,
                    }}
                  >
                    {advertisement.jobDescriptions}
                  </div>
                </div>

                <AdvertisementQualifications
                  advertisement={advertisement}
                  isHome={isHome}
                  educationQualified={educationQualified}
                  setEducationQualified={setEducationQualified}
                />

                <AdvertisementCertificates
                  advertisement={advertisement}
                  isHome={isHome}
                  certificateQualified={certificateQualified}
                  setCertificateQualified={setCertificateQualified}
                />

                <AdvertisementCompetencies advertisement={advertisement} />

                <AdvertisementPsychometricLanguages
                  requisitionId={advertisement.requisitionId}
                />
                <AdvertisementPsychometricSubDomains
                  requisitionId={advertisement.requisitionId}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="pr-4 py-3 d-flex align-items-center justify-content-center">
          {advertisement.willHavePsychometricTest &&
          !isHome &&
          !advertisement.advertisementId ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setDoPsychometricTest(true)}
                disabled={
                  loading ||
                  confirmApply ||
                  showLoginDialog ||
                  showRegisterDialog ||
                  advertisement.wasApplied ||
                  ((!educationQualified || !certificateQualified) && !isHome)
                }
              >
                Do Psychometric Test <OpenInNewIcon />
              </Button>
            </>
          ) : (
            <>
              {!advertisement.advertisementId && (
                <Button
                  className="px-5"
                  disabled={
                    loading ||
                    confirmApply ||
                    showLoginDialog ||
                    showRegisterDialog ||
                    advertisement.wasApplied ||
                    ((!educationQualified || !certificateQualified) && !isHome)
                  }
                  onClick={() => {
                    if (isHome) setShowLoginDialog(true);
                    else setConfirmApply(true);
                  }}
                  variant="contained"
                  type="button"
                  aria-label={`apply on position of ${
                    advertisement.positionName
                  }${
                    advertisement.entityName
                      ? " at " + advertisement.entityName
                      : ""
                  }`}
                >
                  <span aria-hidden="true">Apply now</span>
                </Button>
              )}
            </>
          )}
        </DialogActions>
      </Dialog>

      {confirmApply && (
        <ConfirmationDialog
          disabled={!educationQualified || !certificateQualified}
          btnMessage="Apply"
          confirmationDialog={confirmApply}
          message={`Are you sure you want to apply on this positions`}
          setConfirmationDialog={setConfirmApply}
          onYes={() => {
            doApply(
              {
                advertisementId: advertisement.id,
                source:
                  advertisement.externalAdvert?.source ||
                  externalAdvert?.source ||
                  null,
              },
              () => {
                setConfirmApply(false);
                setShowDialog(false);
                dispatch({
                  type: "SET_SELECTED_ADVERTISEMENT",
                  data: null,
                });
              }
            );
          }}
        />
      )}

      {showLoginDialog && (
        <LoginModal
          showDialog={showLoginDialog}
          setShowDialog={setShowLoginDialog}
          showRegisterDialog={showRegisterDialog}
          setShowRegisterDialog={setShowRegisterDialog}
          source={
            advertisement.externalAdvert?.source ||
            externalAdvert?.source ||
            null
          }
        />
      )}
      {showRegisterDialog && (
        <RegisterModal
          showDialog={showRegisterDialog}
          setShowDialog={setShowRegisterDialog}
          showLoginDialog={showLoginDialog}
          setShowLoginDialog={setShowLoginDialog}
          source={
            advertisement.externalAdvert?.source ||
            externalAdvert?.source ||
            null
          }
        />
      )}

      {doPsychometricTest && (
        <PsychometricTest
          showDialog={doPsychometricTest}
          setShowDialog={setDoPsychometricTest}
          advertisement={advertisement}
          closeAdvertisement={onClose}
        />
      )}
    </>
  );
}

const mapStateToProps = ({
  user,
  env,
  loading,
  onWait,
  userProfileStatus,
}) => ({
  user,
  env,
  loading,
  onWait,
  userProfileStatus,
});

export default connect(mapStateToProps, {
  doApply,
  // getPsychometricLaguages,
  // getPsychometricSession,
})(AdvertisementDetails);
