import React, { useEffect } from "react";

import { Redirect, Route, Switch } from "react-router-dom";

import { connect } from "react-redux";

import Advertisements from "./Advertisements";
import Applications from "./Applications";
import Profile from "./profile";

import ExamLayout from "./exam-portal";

import { isEmpty } from "lodash";
import Exams from "./Exams";

const Index = (props) => {
  const { env, user, loading, isFetchingUser } = props;

  useEffect(() => {
    if (isEmpty(user)) return <Redirect to="/" />;
  }, []);

  return (
    <>
      {!isEmpty(user) && !isFetchingUser && (
        <div className="mt-2">
          <Switch>
            <Route
              path="/applicant/advertisements"
              component={Advertisements}
            />
            <Route path="/applicant/applications" component={Applications} />
            <Route path="/applicant/exams" component={Exams} />
            <Route path="/applicant/profile" component={Profile} />
            <Route path="/applicant/exam-portal" component={ExamLayout} />
            <Route
              path="/applicant"
              component={() => <Redirect to="/applicant/advertisements" />}
            />
          </Switch>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading, env, isFetchingUser }) => {
  return { user, loading, env, isFetchingUser };
};
export default connect(mapStateToProps, {})(Index);
