import { Skeleton } from "@mui/material";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAdvertisementPsychometricLanguages } from "../../../store/applicant/actions";
import ReactPaginate from "react-paginate";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemAvatar,
  Avatar,
} from "@mui/material";

const AdvertisementPsychometricLanguages = (props) => {
  const {
    requisitionId,
    advertisementPsychometricLanguages,
    getAdvertisementPsychometricLanguages,
  } = props;
  useEffect(
    () => {
      getAdvertisementPsychometricLanguages(requisitionId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requisitionId]
  );

  const getNo = (idToSearch) =>
    advertisementPsychometricLanguages.findIndex(({ id }) => id === idToSearch);
  return (
    <>
      {!!advertisementPsychometricLanguages.length && (
        <div className="col-lg-12 mt-3">
          <strong>Psychometric Languages</strong>

          <List
            className="mt-2"
            sx={{
              borderRadius: "8px",
              border: `1px solid #c8c6c6`,
              backgroundColor: "#f9f9f9",
            }}
            dense
          >
            {advertisementPsychometricLanguages.map((row, index) => (
              <React.Fragment key={row.id}>
                <ListItem dense>
                  <ListItemAvatar>
                    <Avatar
                      style={{
                        fontSize: "16px",
                        border: "1px solid #c8c6c6",
                      }}
                      className="bg-white text-dark "
                    >
                      {getNo(row.id) + 1}
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      <span className={`${"font-weight-light"}`}>
                        {row.language?.name === "Francais"
                          ? "Français"
                          : row.language?.name}
                      </span>
                    }
                  />
                </ListItem>

                {advertisementPsychometricLanguages.length > index + 1 && (
                  <Divider />
                )}
              </React.Fragment>
            ))}
          </List>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ loading, advertisementPsychometricLanguages }) => {
  return {
    loading,
    advertisementPsychometricLanguages,
  };
};
export default connect(mapStateToProps, {
  getAdvertisementPsychometricLanguages,
})(AdvertisementPsychometricLanguages);
