import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  ButtonGroup,
  Alert,
  Typography,
} from "@mui/material";

import { Error } from "@mui/icons-material";

import TranslateIcon from "@mui/icons-material/Translate";
import DvrIcon from "@mui/icons-material/Dvr";
import AlertMessage from "../components/AlertMessage";
import LanguageSelection from "./components/LanguageSelection";
import ExamRules from "./components/ExamRules";

import { connect, useDispatch } from "react-redux";

import {
  goToQuestionNo,
  startPsychometricTest,
  getPsychometricSessionLogs,
  getPsychometricLaguages,
  getPsychometricSession,
} from "./../../../store/psychometric/actions";
import DoTest from "./components/DoTest";
import { showError } from "../../toastify";
import SessionLogs from "./components/SessionLogs";
import { doApply } from "../../../store/applicant/actions";

const initialSteps = [
  {
    order: 1,
    label: "Select language",
    icon: TranslateIcon,
    isDone: false,
    isCurrent: true,
  },
  {
    order: 2,
    label: "Instructions",
    icon: Error,
    isDone: false,
    isCurrent: false,
  },

  {
    order: 3,
    label: "Psychometric Test",
    icon: DvrIcon,
    isDone: false,
    isCurrent: false,
  },
];

export const PsychometricTest = (props) => {
  const {
    user,
    loading,
    showDialog,
    setShowDialog,
    advertisement,
    psychometricLaguages,
    psychometricSession,
    psychometricQuestions,
    goToQuestionNo,
    currentPSYQuestionNo,
    startPsychometricTest,
    getPsychometricSessionLogs,
    doApply,
    psychometricInstructions,
    closeAdvertisement,
    getPsychometricLaguages,
    getPsychometricSession,
  } = props;

  const [selectedLanguage, setSelectedLanguage] = useState(null);

  useEffect(() => {
    setSelectedLanguage(
      psychometricLaguages?.find(
        (laguage) => laguage.id === psychometricSession?.preferredLanguageId
      ) || null
    );
  }, [psychometricSession, psychometricLaguages]);

  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(1);
  const [userIp, setUserIp] = useState("");

  const [onSubmit, setOnSubmit] = useState(false);
  const [acceptInstructions, setAcceptInstructions] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    getClientIp();
  }, []);

  useEffect(() => {
    getPsychometricLaguages(advertisement.advertisementId || advertisement.id);
    getPsychometricSession(advertisement.advertisementId || advertisement.id);
  }, [advertisement]);

  useEffect(() => {
    if (!!psychometricSession)
      getPsychometricSessionLogs(psychometricSession?.id);
  }, [psychometricSession, isFullScreen]);

  const getClientIp = async () => {
    try {
      const { data } = await axios.get(`https://api.ipify.org`);

      setUserIp(data);
    } catch (error) {}
  };

  const [steps, setSteps] = useState(initialSteps);

  const [errorMessage, setErrorMessage] = useState(null);

  const onCancel = () => {
    setShowDialog(false);
    closeAdvertisement();
  };

  const handleBackClick = () => {
    setErrorMessage(null);
    const Step = steps.find((step) => step.isCurrent);
    if (Step.order > 1) {
      setCurrentStep(Step.order - 1);
      setSteps((prevSteps) =>
        prevSteps.map((step) =>
          step.order === Step.order - 1
            ? { ...step, isCurrent: true, isDone: false }
            : { ...step, isCurrent: false }
        )
      );
    }
  };

  const handleCompleteStep = () => {
    setErrorMessage(null);
    const Step = steps.find((step) => step.isCurrent);

    if (Step.order === 1) {
      if (selectedLanguage == null) {
        setErrorMessage({
          title: "Language Error",
          description: "Please select a language",
        });
        return;
      } else {
        setCurrentStep(2);
        setSteps((prevSteps) => {
          return prevSteps.map((step) =>
            step.order === 1
              ? { ...step, isCurrent: false, isDone: true }
              : step.order === 2
              ? { ...step, isCurrent: true }
              : step
          );
        });
      }
    }

    if (Step.order === 2) {
      if (!acceptInstructions) {
        setErrorMessage({
          title: "Please accept the instructions to continue",
          description: "",
        });
        return;
      }

      setCurrentStep(3);
      setSteps((prevSteps) => {
        return prevSteps.map((step) =>
          step.order === 2
            ? { ...step, isCurrent: false, isDone: true }
            : step.order === 3
            ? { ...step, isCurrent: true }
            : step
        );
      });
    }
  };

  const onNextQuestion = (n) => {
    setOnSubmit(false);
    goToQuestionNo(n);
  };

  const exitFullScreen = () => {
    if (document.fullscreenElement) document.exitFullscreen();
    setIsFullScreen(false);
  };

  const startTest = async () => {
    if (document.fullscreenEnabled) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement
          .requestFullscreen()
          .then(() => {
            setIsFullScreen(true);
            doApply(
              {
                advertisementId:
                  advertisement.advertisementId || advertisement.id,
                source: null,
              },
              () =>
                startPsychometricTest(
                  {
                    advertisementId:
                      advertisement.advertisementId || advertisement.id,
                    preferredLanguageId: selectedLanguage?.id,
                    userIp,
                  },
                  exitFullScreen
                ),
              false
            );
          })
          .catch((error) => {
            setIsFullScreen(false);
            return showError("Browser fullscreen error");
          });
      }
    } else {
      return showError(
        "Your browser is not supported. Please, change the browser"
      );
    }
  };

  const translate = (i) =>
    selectedLanguage?.id === 1
      ? i.kn
      : selectedLanguage?.id === 2
      ? i.en
      : selectedLanguage?.id === 3
      ? i.fr
      : "";
  return (
    <Dialog
      fullScreen
      open={showDialog}
      sx={{
        "& .MuiPaper-root": {
          border: 1,
          borderColor: "#dcf1ff",
          margin: 0,
          maxWidth: "none",
        },
      }}
    >
      <DialogTitle
        style={{
          fontSize: "15px",
          fontWeight: "normal",
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <div
          className="d-none d-md-block"
          style={{
            fontWeight: "bold",
            fontSize: "28px",
            fontWeight: "bold",
            color: "#1a76d2",
          }}
        >
          Psychometric Test |
        </div>
        <div className="ml-3 d-none d-md-block">
          <div
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              fontWeight: "bold",
              color: "#1a76d2",
            }}
          >
            {advertisement.positionName}
          </div>
          <div
            style={{
              fontSize: "13px",
              marginTop: "-4px",
              marginBottom: "15px",
            }}
          >
            {advertisement.entityName}
          </div>
        </div>

        <IconButton
          disabled={!!isFullScreen}
          aria-label="close advertisement details dialog"
          onClick={() => !isFullScreen && onCancel()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
        {/* )} */}
      </DialogTitle>

      <DialogContent className="pb-0 ">
        <div className="d-none d-md-block">
          <div
            className="px-4"
            style={{
              backgroundColor: "#dcf1ff",
              fontSize: "15px",
              fontWeight: "normal",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                color: "000",
                height: "100%",
                paddingTop: "12px",
              }}
            >
              {!(psychometricQuestions?.length && !!isFullScreen) &&
                steps.map((step, index) => (
                  <div
                    className="d-none d-md-block"
                    key={index}
                    // onClick={() => handleStepClick(step.order)}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "default",
                    }}
                  >
                    <step.icon
                      style={{
                        fontSize: "28px",
                        opacity: step.isCurrent ? "100%" : "40%",
                        color: "#1a76d2",
                      }}
                    />
                    <p
                      style={{
                        fontWeight: step.isCurrent ? "bold" : "normal",
                        color: "#000",
                        fontSize: "12px",
                      }}
                    >
                      {step.label}
                    </p>
                  </div>
                ))}

              {!!psychometricQuestions?.length &&
                !!isFullScreen &&
                !onSubmit && (
                  <>
                    <div></div>
                    <div className="d-none d-md-block">
                      {psychometricQuestions?.map((question, i) => (
                        <span
                          className={`d-inline-flex justify-content-center p-2 border`}
                          // onClick={() => onNextQuestion(i)}
                          style={{
                            backgroundColor: `${
                              currentPSYQuestionNo === i && !onSubmit
                                ? "#00a4ffb0"
                                : question.answered && !question.skipped
                                ? "#abebd0"
                                : question.answered && !!question.skipped
                                ? "#ff63729e"
                                : "#c6d8e5"
                            }`,
                            width: "50px",
                            cursor: "default",
                          }}
                          key={question.id}
                        >
                          {i + 1}
                        </span>
                      ))}
                    </div>
                    <div></div>
                  </>
                )}
            </div>
          </div>
          {!!errorMessage && errorMessage.title && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                backdropFilter: "blur(5px)",
                zIndex: 1299,
              }}
              onClick={() => setErrorMessage(null)}
            >
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1300,
                  background: "transparent",
                  border: "none",
                }}
              >
                <AlertMessage
                  type={"error"}
                  errorMessageTitle={errorMessage.title}
                  errorMessageDescription={errorMessage.description}
                  onClose={() => setErrorMessage(null)}
                />
              </div>
            </div>
          )}

          {currentStep === 1 && !!psychometricLaguages?.length && (
            <LanguageSelection
              advertisement={advertisement}
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
            />
          )}

          {currentStep === 2 && (
            <ExamRules
              acceptInstructions={acceptInstructions}
              setAcceptInstructions={setAcceptInstructions}
              psychometricLaguages={psychometricLaguages}
              selectedLanguage={selectedLanguage}
              translate={translate}
            />
          )}

          {currentStep === 3 && (
            <>
              {!!psychometricQuestions?.length && !!isFullScreen ? (
                <DoTest
                  advertisement={advertisement}
                  onSubmit={onSubmit}
                  setOnSubmit={setOnSubmit}
                  isFullScreen={isFullScreen}
                  setIsFullScreen={setIsFullScreen}
                  exitFullScreen={exitFullScreen}
                  startTest={startTest}
                  translate={translate}
                />
              ) : (
                <div
                  className="d-flex  align-items-center justify-content-center flex-column"
                  style={{
                    minHeight: "50vh",
                    overflow: "auto",
                  }}
                >
                  {!psychometricSession?.submittedOn && (
                    <div
                      style={{
                        width: "40vw",
                      }}
                    >
                      <Alert severity="info" className="mb-3 mt-3">
                        <p>
                          <strong>Important Notice:</strong>
                        </p>
                        <p>
                          {translate(
                            psychometricInstructions.find(({ id }) => id === 20)
                          )}
                        </p>
                        <p>
                          {translate(
                            psychometricInstructions.find(({ id }) => id === 16)
                          )}
                          .{" "}
                          {translate(
                            psychometricInstructions.find(({ id }) => id === 21)
                          )}
                        </p>
                      </Alert>
                    </div>
                  )}
                  {!psychometricSession?.submittedOn && (
                    <div>
                      <Button
                        disabled={loading}
                        onClick={startTest}
                        color="success"
                        variant="contained"
                        sx={{
                          borderRadius: "50px",
                          textAlign: "center",
                          minWidth: "180px",
                          padding: "4px 14px",
                          height: "40px",
                          boxShadow: "none",
                        }}
                      >
                        {loading ? "Wait..." : "START TEST"}
                      </Button>
                    </div>
                  )}
                  {!psychometricSession?.submittedOn && <SessionLogs />}

                  {!!psychometricSession?.submittedOn && (
                    <div
                      className={`text-center text-${
                        !psychometricSession?.submittedOn
                          ? "primary"
                          : psychometricSession?.score >= 50
                          ? "success"
                          : "danger"
                      }`}
                    >
                      {!!psychometricSession?.submittedOn && (
                        <p className="mb-0">
                          {psychometricSession?.score >= 50
                            ? "Congratulations! You passed"
                            : "Unfortunately you failed"}
                        </p>
                      )}
                      {!!psychometricSession?.submittedOn && (
                        <span
                          className={`badge p-2 px-5 badge-${
                            psychometricSession?.score >= 50
                              ? "success"
                              : "danger"
                          }`}
                          style={{ fontSize: "24px" }}
                        >
                          {psychometricSession?.score}%
                        </span>
                      )}{" "}
                      <div>
                        <Button className="mt-1" onClick={() => onCancel()}>
                          close
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        <div className="d-block d-md-none mt-5">
          <Alert severity="error">This divice is not supported.</Alert>
        </div>
      </DialogContent>
      {currentStep <= 3 && !isFullScreen && (
        <DialogActions
          className="d-none d-md-block"
          sx={{
            justifyContent: "space-between",
            width: "100%",
            paddingBottom: "20px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              gap: "8px",
            }}
          >
            <Button
              disabled={loading || currentStep === 1}
              onClick={handleBackClick}
              variant="contained"
              sx={{
                backgroundColor: "#f5f5f5",
                color: "#000",
                borderRadius: "50px",
                textAlign: "center",
                width: "180px",
                height: "40px",
                boxShadow: "none",
                "&:hover": {
                  backgroundColor: "#e0e0e0",
                  boxShadow: "none",
                },
              }}
            >
              Back
            </Button>

            <Button
              disabled={
                loading ||
                (currentStep === 1 && !selectedLanguage) ||
                (currentStep === 2 && !acceptInstructions) ||
                currentStep === 3
              }
              onClick={handleCompleteStep}
              color="primary"
              variant="contained"
              sx={{
                borderRadius: "50px",
                textAlign: "center",
                minWidth: "180px",
                padding: "4px 14px",
                height: "40px",
                boxShadow: "none",
              }}
            >
              Continue
            </Button>
          </div>
        </DialogActions>
      )}
    </Dialog>
  );
};

const mapStateToProps = ({
  user,
  loading,
  psychometricLaguages,
  psychometricSession,
  psychometricQuestions,
  currentPSYQuestionNo,
  psychometricInstructions,
}) => ({
  user,
  loading,
  psychometricLaguages,
  psychometricSession,
  psychometricQuestions,
  currentPSYQuestionNo,
  psychometricInstructions,
});

export default connect(mapStateToProps, {
  goToQuestionNo,
  startPsychometricTest,
  getPsychometricSessionLogs,
  doApply,
  getPsychometricLaguages,
  getPsychometricSession,
})(PsychometricTest);
