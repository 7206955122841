import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getApplications } from "../../store/applicant/actions";
import NoResults from "../comm/NoResults";
import SearchBox from "../comm/SearchBox";
import ProfileSummary from "./profile/components/ProfileStatus";

import AdvertSkeleton from "../comm/AdvertSkeleton";
import ApplicationItem from "./components/ApplicationItem";

import ApplicationGuidelines from "../home/components/ApplicationGuidelines";

export const Exams = (props) => {
  const { loading, applications, getApplications } = props;

  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState("");
  const [allHidden, setAllHidden] = useState(0);
  const [showHidden, setShowHidden] = useState(0);

  useEffect(() => {
    // update page title
    document.title = "Applications | Jobs";
  }, []);

  useEffect(() => {
    setFilteredJobs(
      applications.filter((application) =>
        (
          (application.positionName || "") +
          (application.entityName || "") +
          (application.apStatusName || "") +
          (application.writtenExamStatus || "") +
          (application.oralExamStatus || "") +
          (application.jobOfferStatusName || "") +
          (application.waitingListStatusName || "") +
          (application.entityAcronym || "")
        )
          .replace(/\s/g, "")
          .toLowerCase()
          .includes(searchInput.replace(/\s/g, "").toLowerCase())
      )
    );
  }, [applications, searchInput]);

  useEffect(() => {
    if (!applications.length) getApplications(showHidden, setAllHidden);
  }, [showHidden]);

  const randomArray = Array.from({ length: 10 }, () =>
    Math.floor(Math.random() * 10)
  );

  const [filteredJobs, setFilteredJobs] = useState([]);

  return (
    <div className="row mx-0 mt-4">
      <div className="col-12 col-sm-8 col-md-9 order-last order-sm-first mt-sm-0 px-1 px-sm-3">
        <section className="card mb-3 elevated rounded border border-primary bg-light">
          <div>
            <div className="card-header bg-light rounded-top font-weight-bold ">
              <div className="my-2 d-flex flex-column flex-md-row justify-content-md-between align-items-center ">
                <Typography
                  variant="h6"
                  component="h2"
                  className={`text-uppercase ${
                    showHidden ? "text-danger" : ""
                  }`}
                  aria-labelledby="my-application-list"
                >
                  <span className="mr-2">
                    {!showHidden ? "My" : " Hidden"} applications (
                    {applications.length})
                  </span>

                  <Button
                    variant="text"
                    disabled={loading || !allHidden}
                    onClick={() => {
                      dispatch({ type: "SET_APPLICATIONS", data: [] });
                      setShowHidden(+!showHidden);
                    }}
                    size="small"
                    className="px-0 text-blue-main"
                    aria-label={`Show ${
                      showHidden ? "unhidden" : "hidden"
                    } applications`}
                  >
                    <span aria-hidden="true">
                      Show {showHidden ? " Unhidden" : " Hidden"}
                    </span>
                  </Button>
                </Typography>

                <div className="d-flex flex-row align-items-center mt-2 justify-content-between">
                  <SearchBox
                    placeholder="Search..."
                    disabled={!applications.length || loading}
                    onSearch={setSearchInput}
                    role="search"
                  />
                </div>
              </div>
            </div>
            <div className="card-body" role="list" id="my-application-list">
              {loading && !applications.length ? (
                randomArray.map((item) => (
                  <div
                    className="mb-3"
                    key={item + Math.random() * 11}
                    role="listitem"
                  >
                    <AdvertSkeleton />
                  </div>
                ))
              ) : filteredJobs.length ? (
                filteredJobs.map((application, index) => (
                  <div className="mb-3" key={application.id} role="listitem">
                    <ApplicationItem application={application} />
                  </div>
                ))
              ) : (
                <NoResults />
              )}
            </div>
          </div>
        </section>
      </div>
      <aside className="col-12 mb-3 col-sm-4 col-md-3 order-first order-sm-last px-1 px-sm-3">
        <ProfileSummary />
      </aside>
    </div>
  );
};

const mapStateToProps = ({ user, loading, applications }) => ({
  user,
  loading,
  applications,
});

export default connect(mapStateToProps, {
  getApplications,
})(Exams);
