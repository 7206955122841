import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { register } from "../../../store/comm/actions";
import { useRef } from "react";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import UnpublishedRoundedIcon from "@mui/icons-material/UnpublishedRounded";
import { useHistory } from "react-router-dom";
import MaskedInput from "react-text-mask";
import axios from "axios";
import { showError, showSuccess } from "../../toastify";
import { isEmailValid } from "../../utils";

const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
const eightCharsOrMore = /.{8,}/g; // eight characters or more
export const ID_NUMBER_INPUT_PATTERN = [
  /[1-3]/,
  " ",
  /[1-2]/,
  /[0|9]/,
  /\d/,
  /\d/,
  " ",
  /[7-8]/,
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  " ",
  /\d/,
  " ",
  /\d/,
  /\d/,
];

const steps = [
  {
    label: "Basic info",
    description: "Enter your name and personal details.",
  },
  {
    label: "Email Verification",
    description: "Check your email and enter the verification code.",
  },
  {
    label: "Password setting",
    description: "Create a strong password for your account.",
  },
];
//[("Basic info", "Email verification", "Password setting")];

function RegisterModal(props) {
  const { showDialog, setShowDialog, setShowLoginDialog, register } = props;

  const history = useHistory();

  const [userInfo, setUserInfo] = useState({
    nid: "",
    email: "",
    phone: "",
  });

  const [loading, setLoading] = useState(false);

  const [nidLoading, setNidLoading] = useState(false);
  const [nidData, setNidData] = useState(null);
  const [nidError, setNidError] = useState("");
  const [formError, setFormError] = useState(null);

  const [confirmationCode, setConfirmationCode] = useState("");

  const [formStep, setFormStep] = useState(1);
  const pin1Ref = useRef(null);
  const pin2Ref = useRef(null);
  const pin3Ref = useRef(null);
  const pin4Ref = useRef(null);

  const [pin, setPin] = useState({ one: "", two: "", three: "", four: "" });

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    return () => {
      setFormStep(1);
      setConfirmationCode("");
      setPin({ one: "", two: "", three: "", four: "" });
      setPassword("");
      setConfirmPassword("");
    };
  }, []);

  const passwordTracker = {
    uppercase: password.match(atLeastOneUppercase),
    lowercase: password.match(atLeastOneLowercase),
    number: password.match(atLeastOneNumeric),
    specialChar: password.match(atLeastOneSpecialChar),
    eightCharsOrGreater: password.match(eightCharsOrMore),
    confirmed:
      password.length >= 8 &&
      confirmPassword.length >= password.length &&
      confirmPassword === password
        ? true
        : confirmPassword.length >= password.length &&
          confirmPassword !== password
        ? false
        : undefined,
  };

  const passwordStrength = Object.values(passwordTracker).filter(
    (value) => value
  ).length;

  const onNIDChange = (e) => {
    setNidError("");
    setNidLoading(false);
    setNidData("");

    const nid = e.target.value;
    setUserInfo({ ...userInfo, nid });

    if (nid.length === 21) verifyNationalID(nid);
  };

  const onClose = () => {
    setShowDialog(false);
  };

  const isCodeMatches = (original, pins) => {
    if (
      !pins.one.length ||
      !pins.two.length ||
      !pins.three.length ||
      !pins.four.length
    )
      return null;
    const code = pins.one + pins.two + pins.three + pins.four;
    if (+code === original) return true;
    return false;
  };

  const handleRegister = () => {
    if (formStep === 1) {
      verifyEmail();
    }

    if (formStep === 2 && isCodeMatches(confirmationCode, pin) === true)
      setFormStep(3);

    if (formStep === 3 && passwordStrength === 6) {
      register(
        {
          idNumber: (userInfo.nid || "").replace(/\s/g, ""),
          email: userInfo.email,
          phoneNumber: userInfo.phone,
          password: password,
        },
        setLoading,
        setShowDialog,
        history
      );
    }
  };

  const onPasteAssignPin = (e) => {
    const value = e.clipboardData.getData("text/plain");

    if (!isNaN(value)) {
      const codesArray = value.split("");
      setPin({
        one: codesArray[0] || "",
        two: codesArray[1] || "",
        three: codesArray[2] || "",
        four: codesArray[3] || "",
      });
    }
    e.preventDefault();
  };

  const handleOnDeletePin = (e, pinName) => {
    if (
      (e.key === "Delete" || e.key === "Backspace") &&
      e.target.value === ""
    ) {
      if (pinName === "four") pin3Ref.current.focus();
      if (pinName === "three") pin2Ref.current.focus();
      if (pinName === "two") pin1Ref.current.focus();
    }
  };

  const verifyNationalID = async (idNumber) => {
    try {
      setNidError("");
      setNidLoading(true);
      setNidData("");

      const { data } = await axios.get(
        `/api/external/verify-nid-number/${(idNumber || "").replace(/\s/g, "")}`
      );

      setNidData(data);
      setNidLoading(false);
    } catch (error) {
      setNidError(error.response.data);
      setNidLoading(false);
    }
  };

  const verifyEmail = async () => {
    try {
      setLoading(true);
      setConfirmationCode("");

      const { data } = await axios.post(`/api/auth/verify-email`, {
        idNumber: (userInfo.nid || "").replace(/\s/g, ""),
        email: userInfo.email,
        phoneNumber: userInfo.phone,
      });

      setConfirmationCode(data.code);
      setPin({ one: "", two: "", three: "", four: "" });
      setFormStep(2);
      setLoading(false);
      showSuccess(data.message);
    } catch (error) {
      setConfirmationCode("");
      setLoading(false);
      showError(error);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <Dialog
      // onClose={onClose}
      open={showDialog}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <Typography variant="overline" display="block">
          <span className="text-dark">
            {formStep === 2
              ? "Email verification"
              : "Register to e-recruitment"}
          </span>
        </Typography>
        <IconButton
          // aria-label="close registration form"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <span className="material-icons">close</span>
        </IconButton>
      </DialogTitle>

      <DialogContent className="pb-0 mb-0">
        <div
          style={{
            minHeight: "45vh",
          }}
        >
          <Box sx={{ width: "100%" }}>
            {/* Live region for step changes */}
            <Typography
              id="stepper-live-region"
              aria-live="polite"
              style={{ position: "absolute", left: "-9999px" }}
            >
              You are on step {formStep}: {steps[formStep - 1].label}
            </Typography>

            <Stepper
              activeStep={formStep - 1}
              alternativeLabel
              aria-live="polite"
            >
              {steps.map((step, index) => (
                <Step key={step}>
                  <StepLabel
                    aria-label={`Step ${index + 1}: ${step.label}. ${
                      step.description
                    }`}
                  >
                    <span aria-hidden>{step.label}</span>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <form>
            <div className="pt-3 mx-0 px-0 row">
              {!!formError && (
                <Alert
                  icon={false}
                  severity="error"
                  className="mb-2 w-100 mx-3"
                  aria-live="assertive"
                >
                  <small>{formError.msg}</small>
                </Alert>
              )}

              {formStep === 2 && (
                <Alert
                  icon={false}
                  severity="info"
                  className="mb-2mb-2 w-100 mx-3"
                  aria-live="polite"
                >
                  <small>
                    We have sent a <strong>verification code</strong> to your
                    email <strong>{userInfo.email}</strong>, please enter the{" "}
                    <strong>code</strong> to complete registration.
                  </small>
                </Alert>
              )}

              {formStep === 1 && (
                <div className="col-12">
                  {!nidData && !nidLoading && !nidError && (
                    <span className="material-icons loaderIcon text-info">
                      help
                    </span>
                  )}

                  {nidLoading && (
                    <i
                      className="fas fa-spinner loaderIcon text-primary loader2"
                      style={{ fontSize: "22px" }}
                      aria-label="verifying your national ID number"
                    ></i>
                  )}

                  {!nidLoading && !!nidError && (
                    <span className="loaderIcon text-danger d-flex align-items-center mr-4">
                      <span className="material-icons text-danger">
                        report_problem
                      </span>
                      <span style={{ fontSize: "10px" }}>Error</span>
                    </span>
                  )}

                  {nidData && !nidLoading && (
                    <span className="loaderIcon text-success d-flex align-items-center">
                      <span className="material-icons text-success">
                        verified
                      </span>
                    </span>
                  )}

                  <label htmlFor="nid" className="sr-only">
                    National ID Number
                  </label>
                  <MaskedInput
                    id="nid"
                    mask={ID_NUMBER_INPUT_PATTERN}
                    className="form-control"
                    placeholder="Enter your NID Number"
                    guide={false}
                    disabled={loading}
                    value={userInfo.nid}
                    onChange={onNIDChange}
                    onKeyPress={(e) => {
                      if (e.key === "Enter" && e.target.value.length === 21) {
                        verifyNationalID(e.target.value);
                      }
                    }}
                  />
                  {nidData && (
                    <small className="my-1 text-blue" aria-live="polite">
                      {`${nidData.surnames} ${nidData.foreName}`}{" "}
                      <span className="badge badge-success">Verified</span>
                    </small>
                  )}

                  {!!nidError && (
                    <small className="my-1 text-danger" aria-live="assertive">
                      {nidError}
                    </small>
                  )}

                  <FormControl
                    fullWidth
                    variant="outlined"
                    className="mb-3 mt-3"
                    size="small"
                  >
                    <InputLabel htmlFor="email">Your email</InputLabel>
                    <OutlinedInput
                      id="email"
                      placeholder="Enter your email"
                      type="text"
                      name="email"
                      value={userInfo.email}
                      onChange={(e) => {
                        const email = e.target.value;
                        setUserInfo({ ...userInfo, email });
                      }}
                      onBlur={(e) => {
                        if (!e.target.value)
                          setFormError({
                            type: "email",
                            msg: "Email is required!",
                          });
                        else if (!isEmailValid(e.target.value))
                          setFormError({
                            type: "email",
                            msg: "Please provide a valid email!",
                          });
                        else if (formError?.type === "email")
                          setFormError(null);
                      }}
                      // label="Your email"
                      disabled={loading}
                      required={true}
                      error={formError?.type === "email"}
                    />

                    {!!nidError && (
                      <span
                        className="loaderIcon text-danger d-flex align-items-center mr-4"
                        aria-hidden
                      >
                        <span className="material-icons text-danger">
                          report_problem
                        </span>
                        <span style={{ fontSize: "10px" }}>Error</span>
                      </span>
                    )}
                  </FormControl>

                  <FormControl
                    fullWidth
                    variant="outlined"
                    className="mb-3"
                    size="small"
                  >
                    <InputLabel htmlFor="phone_number">
                      Your phone number
                    </InputLabel>
                    <OutlinedInput
                      id="phone_number"
                      placeholder="Enter your phone number"
                      type="text"
                      name="phone_number"
                      value={userInfo.phone}
                      onChange={(e) => {
                        const phone = e.target.value;
                        setUserInfo({ ...userInfo, phone });
                      }}
                      onBlur={(e) => {
                        if (!e.target.value)
                          setFormError({
                            type: "phone",
                            msg: "Phone number is required!",
                          });
                        else if (e.target.value.length !== 10)
                          setFormError({
                            type: "phone",
                            msg: "Phone number be 10 numbers!",
                          });
                        else if (formError?.type === "phone")
                          setFormError(null);
                      }}
                      // label="Your phone number"
                      required={true}
                      disabled={loading}
                      error={formError?.type === "phone"}
                    />
                  </FormControl>
                </div>
              )}

              {formStep === 2 && (
                <div className="col-12 text-center mt-4">
                  <div aria-live="polite" className="sr-only">
                    <p>
                      Please enter the verification code you received via email.
                      If you did not receive the code, check your spam folder or
                      request a new one.
                    </p>
                    <p>
                      Enter the <strong>first digit</strong>,{" "}
                      <strong>second digit</strong>,{" "}
                      <strong>third digit</strong>, and{" "}
                      <strong>fourth digit</strong> of the code in the fields
                      below.
                    </p>
                  </div>

                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <label htmlFor="firstPin" className="sr-only">
                      Enter the first digit
                    </label>
                    <input
                      ref={pin1Ref}
                      id="firstPin"
                      placeholder="-"
                      type="text"
                      value={pin.one}
                      onChange={(e) => {
                        setPin({ ...pin, one: e.target.value });
                        if (!!e.target.value.length && pin2Ref.current)
                          pin2Ref.current.focus();
                      }}
                      disabled={loading}
                      maxLength={1}
                      style={{
                        width: "50px",
                        height: "40px",
                        fontSize: "22px",
                      }}
                      className="form-control text-center mx-2"
                      onPaste={onPasteAssignPin}
                      onKeyDown={(e) => handleOnDeletePin(e, "one")}
                      autoFocus
                      aria-describedby="pin1Help"
                    />
                    <span id="pin1Help" className="sr-only">
                      Enter the first digit of your verification code
                    </span>

                    <label htmlFor="secondPin" className="sr-only">
                      Enter the second digit
                    </label>
                    <input
                      ref={pin2Ref}
                      id="secondPin"
                      placeholder="-"
                      type="text"
                      value={pin.two}
                      onChange={(e) => {
                        setPin({ ...pin, two: e.target.value });
                        if (!!e.target.value.length && pin3Ref.current)
                          pin3Ref.current.focus();
                      }}
                      disabled={loading}
                      maxLength={1}
                      style={{
                        width: "50px",
                        height: "40px",
                        fontSize: "22px",
                      }}
                      className="form-control text-center mx-2"
                      onPaste={onPasteAssignPin}
                      onKeyDown={(e) => handleOnDeletePin(e, "two")}
                      aria-describedby="pin2Help"
                    />
                    <span id="pin2Help" className="sr-only">
                      Enter the second digit of your verification code
                    </span>

                    <label htmlFor="thirdPin" className="sr-only">
                      Enter the third digit
                    </label>
                    <input
                      ref={pin3Ref}
                      id="thirdPin"
                      placeholder="-"
                      type="text"
                      value={pin.three}
                      onChange={(e) => {
                        setPin({ ...pin, three: e.target.value });
                        if (!!e.target.value.length && pin4Ref.current)
                          pin4Ref.current.focus();
                      }}
                      disabled={loading}
                      maxLength={1}
                      style={{
                        width: "50px",
                        height: "40px",
                        fontSize: "22px",
                      }}
                      className="form-control text-center mx-2"
                      onPaste={onPasteAssignPin}
                      onKeyDown={(e) => handleOnDeletePin(e, "three")}
                      aria-describedby="pin3Help"
                    />
                    <span id="pin3Help" className="sr-only">
                      Enter the third digit of your verification code
                    </span>

                    <label htmlFor="fourthPin" className="sr-only">
                      Enter the fourth digit
                    </label>
                    <input
                      ref={pin4Ref}
                      id="fourthPin"
                      placeholder="-"
                      type="text"
                      value={pin.four}
                      onChange={(e) => {
                        setPin({ ...pin, four: e.target.value });
                        if (!!e.target.value.length && pin4Ref.current)
                          pin4Ref.current.focus();
                      }}
                      disabled={loading}
                      maxLength={1}
                      style={{
                        width: "50px",
                        height: "40px",
                        fontSize: "22px",
                      }}
                      className="form-control text-center mx-2"
                      onPaste={onPasteAssignPin}
                      onKeyDown={(e) => handleOnDeletePin(e, "four")}
                      aria-describedby="pin4Help"
                    />
                    <span id="pin4Help" className="sr-only">
                      Enter the fourth digit of your verification code
                    </span>
                  </div>
                  {isCodeMatches(confirmationCode, pin) === false && (
                    <div role="alert" aria-live="assertive" aria-atomic="true">
                      <small className="mx-2 text-danger">
                        The email verification code does not match. Please try
                        again or request a new code.
                      </small>
                    </div>
                  )}
                  {isCodeMatches(confirmationCode, pin) === true && (
                    <small className={`mx-2 text-success`}>
                      Email verification code matches.
                    </small>
                  )}

                  {formStep === 2 &&
                    isCodeMatches(confirmationCode, pin) !== true && (
                      <Button
                        onClick={verifyEmail}
                        className="my-3 pr-0"
                        disabled={loading}
                        aria-label="Resend verification code"
                      >
                        Resend the code
                      </Button>
                    )}
                </div>
              )}

              {formStep === 3 && (
                <div className="col-12 row mx-0 align-items-center">
                  <div className="col-12 col-md-6 px-0 mb-2 order-last order-md-first">
                    {/* Hidden screen reader text with password criteria */}
                    <div
                      id="password-criteria"
                      className="sr-only"
                      aria-live="polite"
                    >
                      Password must contain at least one uppercase letter, one
                      lowercase letter, one number, and one special character.
                    </div>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className="mb-3"
                      size="small"
                    >
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <OutlinedInput
                        id="password"
                        placeholder="Enter password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        label="Password"
                        aria-required="true"
                        aria-describedby="password-criteria"
                        disabled={loading}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              aria-label={`${
                                showPassword ? "hide" : "show"
                              } password`}
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                              edge="end"
                            >
                              {showPassword ? (
                                <span className="material-icons">
                                  visibility_off
                                </span>
                              ) : (
                                <span className="material-icons">
                                  visibility
                                </span>
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>

                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel htmlFor="confirmPassword">
                        Confirm password
                      </InputLabel>
                      <OutlinedInput
                        id="confirmPassword"
                        placeholder="Confirm password"
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        label="Confirm password"
                        disabled={loading}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              aria-label="toggle confirmed password visibility"
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <span className="material-icons">
                                  visibility_off
                                </span>
                              ) : (
                                <span className="material-icons">
                                  visibility
                                </span>
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>

                  {/* Password criteria with live updates */}
                  <div className="col-12 px-md-3 col-md-6 rounded text-left p-0 mb-2 order-first order-md-last">
                    <Alert
                      severity="info"
                      icon={false}
                      className="mb-2 rounded border"
                    >
                      <div className=" rounded text-left py-1 mb-3">
                        <div className="w-full rounded password-strength-status-dialog"></div>
                        <span>Password must include:</span>
                        <div
                          className={`d-flex flex-row align-items-center ${
                            passwordTracker.lowercase ? "text-success" : ""
                          }`}
                          aria-live="assertive"
                        >
                          {passwordTracker.lowercase ? (
                            <CheckCircleOutlineRoundedIcon
                              style={{ fontSize: "16px", marginTop: "3px" }}
                            />
                          ) : (
                            <CircleOutlinedIcon
                              style={{ fontSize: "16px", marginTop: "3px" }}
                            />
                          )}
                          <span className="ml-1">lowercase</span>
                        </div>
                        <div
                          className={`d-flex flex-row align-items-center ${
                            passwordTracker.uppercase ? "text-success" : ""
                          }`}
                          aria-live="assertive"
                        >
                          {passwordTracker.uppercase ? (
                            <CheckCircleOutlineRoundedIcon
                              style={{ fontSize: "16px", marginTop: "3px" }}
                            />
                          ) : (
                            <CircleOutlinedIcon
                              style={{ fontSize: "16px", marginTop: "3px" }}
                            />
                          )}
                          <span className="ml-1">UPPERCASE</span>
                        </div>
                        <div
                          className={`d-flex flex-row align-items-center ${
                            passwordTracker.number ? "text-success" : ""
                          }`}
                          aria-live="assertive"
                        >
                          {passwordTracker.number ? (
                            <CheckCircleOutlineRoundedIcon
                              style={{ fontSize: "16px", marginTop: "3px" }}
                            />
                          ) : (
                            <CircleOutlinedIcon
                              style={{ fontSize: "16px", marginTop: "3px" }}
                            />
                          )}
                          <span className="ml-1">Number</span>
                        </div>
                        <div
                          className={`d-flex flex-row align-items-center ${
                            passwordTracker.specialChar ? "text-success" : ""
                          }`}
                          aria-live="assertive"
                        >
                          {passwordTracker.specialChar ? (
                            <CheckCircleOutlineRoundedIcon
                              style={{ fontSize: "16px", marginTop: "3px" }}
                            />
                          ) : (
                            <CircleOutlinedIcon
                              style={{ fontSize: "16px", marginTop: "3px" }}
                            />
                          )}
                          <span className="ml-1">Special character</span>
                        </div>
                        <div
                          className={`d-flex flex-row align-items-center ${
                            passwordTracker.eightCharsOrGreater
                              ? "text-success"
                              : ""
                          }`}
                          aria-live="assertive"
                        >
                          {passwordTracker.eightCharsOrGreater ? (
                            <CheckCircleOutlineRoundedIcon
                              style={{ fontSize: "16px", marginTop: "3px" }}
                            />
                          ) : (
                            <CircleOutlinedIcon
                              style={{ fontSize: "16px", marginTop: "3px" }}
                            />
                          )}
                          <span className="ml-1">8 or more characters</span>
                        </div>
                        <div
                          className={`d-flex flex-row align-items-center ${
                            passwordTracker.confirmed
                              ? "text-success"
                              : passwordTracker.confirmed === false
                              ? "text-danger"
                              : ""
                          }`}
                          aria-live="assertive"
                        >
                          {passwordTracker.confirmed ? (
                            <CheckCircleOutlineRoundedIcon
                              style={{ fontSize: "16px", marginTop: "3px" }}
                            />
                          ) : passwordTracker.confirmed === false ? (
                            <UnpublishedRoundedIcon
                              style={{ fontSize: "16px", marginTop: "3px" }}
                            />
                          ) : (
                            <CircleOutlinedIcon
                              style={{ fontSize: "16px", marginTop: "3px" }}
                            />
                          )}
                          <span className="ml-1">confirmed</span>
                        </div>
                      </div>
                    </Alert>
                  </div>
                </div>
              )}
            </div>
          </form>

          <style>
            {`
          .password-strength-status-dialog {
            height: 0.4rem;
            background-color: lightgrey;
            border-radius: 3px;
            margin: 0.5rem 0;
          }

          .password-strength-status-dialog::before {
            content: "";
            background-color: ${
              ["red", "orange", "#03a2cc", "#03a2cc", "#33FFBD", "#33FF57"][
                passwordStrength - 1
              ] || ""
            };
            height: 100%;
            width: ${(passwordStrength / 6) * 100}%;
            display: block;
            border-radius: 3px;
            transition: width 0.2s;
          }
        `}
          </style>
        </div>
      </DialogContent>
      <DialogActions className="d-flex flex-column justify-content-center pb-3">
        <>
          <div className="d-flex flex-row align-items-center">
            {formStep > 1 && (
              <Button
                onClick={() => {
                  setFormStep(formStep - 1);
                }}
                className="my-3 px-4 p-1 text-secondary"
                disabled={loading}
                variant="outlined"
              >
                Go back
              </Button>
            )}

            {!loading && (
              <Button
                disabled={
                  loading ||
                  !nidData ||
                  !userInfo.email ||
                  !userInfo.phone ||
                  !!formError ||
                  userInfo.phone.length !== 10 ||
                  (formStep === 2 && !isCodeMatches(confirmationCode, pin)) ||
                  (formStep === 3 && passwordStrength !== 6)
                }
                onClick={handleRegister}
                variant="contained"
                disableElevation
                className="px-4 ml-2 d-flex align-items-center"
              >
                {formStep === 3 ? "Submit" : "Next"}

                {formStep !== 3 && (
                  <span className="material-icons">
                    keyboard_double_arrow_right
                  </span>
                )}
              </Button>
            )}
            {loading && (
              <Button
                variant="contained"
                disableElevation
                className="px-4 ml-2"
                disabled={loading || nidData === null}
              >
                Wait...
              </Button>
            )}
          </div>
          {formStep === 1 && (
            <small className="mt-1">If you have an account</small>
          )}
          {formStep === 1 && (
            <span className="mt-n1">
              Please,
              <button
                size="small"
                onClick={() => {
                  setShowLoginDialog(true);
                  setShowDialog(false);
                }}
                className="btn btn-sm btn-link"
                disabled={loading}
              >
                Login
              </button>
            </span>
          )}
        </>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = ({ user, loading }) => {
  return {
    user,
    loading,
  };
};

export default connect(mapStateToProps, {
  register,
})(RegisterModal);
