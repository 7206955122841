import React, { useEffect } from "react";
import { connect } from "react-redux";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useHistory } from "react-router-dom";
import loadingGif from "../../../assets/loading.gif";
import { Button, Link } from "@mui/material";

const DisplayMarks = (props) => {
  const { results } = props;

  const history = useHistory();

  useEffect(() => {
    document.title = "Examination Portal - Results";
  }, []);

  const isExamPassed = () =>
    !!results?.score && !!results?.totalMarks
      ? +results?.score >= +results?.totalMarks / 2.0
        ? true
        : false
      : null;

  const handleClose = () => {
    history.push("/applicant/applications");
  };

  return (
    <>
      {!!results ? (
        <div className="container-fluid">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-4 text-center">
              <div
                className={`rounded elevated-1 d-flex flex-column align-items-center px-3 py-4 text-center mt-4 border ${
                  isExamPassed() === true
                    ? "border-success"
                    : isExamPassed() === false
                    ? "border-danger"
                    : "border-info"
                }`}
              >
                {isExamPassed() !== null && (
                  <h6 className="font-weight-bold">Your result</h6>
                )}

                {isExamPassed() ? (
                  <CheckCircleOutlineRoundedIcon
                    className="text-success"
                    style={{ fontSize: "80px" }}
                  />
                ) : isExamPassed() === false ? (
                  <HighlightOffIcon
                    className="text-danger"
                    style={{ fontSize: "80px" }}
                  />
                ) : (
                  <InfoRoundedIcon
                    className="text-info"
                    style={{ fontSize: "80px" }}
                  />
                )}

                {isExamPassed() !== null ? (
                  <span
                    style={{ fontSize: "75px" }}
                    className={`mt-n4 ${
                      isExamPassed() === true
                        ? "text-success"
                        : isExamPassed() === false
                        ? "text-danger"
                        : ""
                    }`}
                  >
                    {(+results?.score * 100.0) / +results?.totalMarks}%
                  </span>
                ) : (
                  <div className="alert alert-info" role="alert">
                    You will be notified of the results after marking!
                  </div>
                )}

                <h6
                  className={`font-weight-bold ${
                    isExamPassed() === true
                      ? "text-success"
                      : isExamPassed() === false
                      ? "text-danger"
                      : ""
                  }`}
                  aria-label={`${
                    isExamPassed()
                      ? "Congratulation you passed exam!"
                      : isExamPassed() === false
                      ? "Sorry you failed exam!"
                      : "Exam Successfully submitted!"
                  }`}
                >
                  <span aria-hidden="true">
                    {isExamPassed()
                      ? "Passed"
                      : isExamPassed() === false
                      ? "Failed"
                      : "Successfully submitted!"}
                  </span>
                </h6>
              </div>

              {results?.externalAssmentUrl &&
                (+results?.score * 100.0) / +results?.totalMarks >=
                  results?.externalAssmentScored && (
                  <a
                    href={results?.externalAssmentUrl}
                    style={{ textDecoration: "none" }}
                    className="mr-1"
                    target="_blank"
                  >
                    <Button
                      disableElevation
                      size="small"
                      color="primary"
                      className="mt-4 px-3"
                      variant="contained"
                    >
                      <span aria-hidden="true">
                        Go to {results?.externalAssmentTitle}
                      </span>
                    </Button>
                  </a>
                )}
              {!!results?.externalAssmentUrl &&
                (+results?.score * 100.0) / +results?.totalMarks <
                  results?.externalAssmentScored && (
                  <button
                    type="button"
                    className="btn btn-info btn-sm mt-4 px-3"
                    onClick={handleClose}
                    aria-label="Close the exam portal"
                  >
                    <span aria-hidden="true">Close</span>
                  </button>
                )}
              {!results?.externalAssmentUrl && (
                <button
                  type="button"
                  className="btn btn-info btn-sm mt-4 px-3"
                  onClick={handleClose}
                  aria-label="Close the exam portal"
                >
                  <span aria-hidden="true">Close</span>
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center mt-5 pt-5">
          <img src={loadingGif} height="128" alt="loading" />
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ results }) => ({
  results,
});

export default connect(mapStateToProps, {})(DisplayMarks);
