import React from "react";
import PropTypes from "prop-types";

const ExamFileViewer = ({ fileUrl }) => {
  return (
    <div>
      <p id="pdfDescription" className="sr-only">
        Embedded PDF file for the exam. Use screen reader shortcuts to navigate.
      </p>
      <embed
        src={fileUrl + "#toolbar=0"}
        type="application/pdf"
        height="100%"
        width="100%"
        aria-labelledby="pdfDescription"
      />
    </div>
  );
};

ExamFileViewer.propTypes = {
  fileUrl: PropTypes.string,
};

export default ExamFileViewer;
