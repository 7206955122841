import axios from "../../app/utils/axios-middleware";
import { showSuccess, showError } from "../../app/toastify";

import TYPES from "./action-types";
import COM_TYPES from "../comm/action-types";
const types = { ...COM_TYPES, ...TYPES };

export const goToQuestionNo = (questionNo, cb = null) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_CURRENT_PSY_QUETION_NO, no: questionNo });
    !!cb && cb();
  };
};

export const setQuestionTime = (questionTime) => {
  return async (dispatch) => {
    dispatch({ type: types.SET_QUETION_TIME, questionTime });
  };
};

export const getPsychometricLaguages = (advertisementId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({ type: types.SET_PSYCHOMETRIC_LANGUAGES, data: [] });

      const { data } = await axios.get(
        `/api/psychometric/test-laguages/${advertisementId}`
      );

      dispatch({
        type: types.SET_PSYCHOMETRIC_LANGUAGES,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getPsychometricSession = (advertisementId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      dispatch({ type: types.SET_PSYCHOMETRIC_SESSION, data: null });

      const { data } = await axios.get(
        `/api/psychometric/session/${advertisementId}`
      );

      dispatch({
        type: types.SET_PSYCHOMETRIC_SESSION,
        data: data,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const startPsychometricTest = (payload, exitFullScreen) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(`/api/psychometric/start`, payload);

      dispatch({
        type: types.SET_PSYCHOMETRIC_QUESTIONS,
        data: data.questions,
      });

      dispatch({
        type: types.SET_PSYCHOMETRIC_SESSION,
        data: data.session,
      });

      dispatch({
        type: types.SET_FEEDBACK_QUESTIONS,
        data: data.feedbackQuestions,
      });

      dispatch({ type: types.END_LOADING });
    } catch (error) {
      exitFullScreen();
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const savePSYAnswer = (payload, cb) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(
        `/api/psychometric/save-answer`,
        payload
      );

      dispatch({
        type: types.UPDATE_PSYCHOMETRIC_QUESTION,
        data: data,
      });

      dispatch({ type: types.END_LOADING });

      cb();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const submitTest = (payload, cb = null) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.START_LOADING });

      const { data } = await axios.post(`/api/psychometric/submit`, payload);

      dispatch({
        type: types.SET_PSYCHOMETRIC_SESSION,
        data: data.session,
      });

      dispatch({
        type: "UPDATE_APPLICATION",
        data: data.application,
      });

      dispatch({ type: types.END_LOADING });

      !!cb && cb();
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const saveQuestionViews = (payload) => {
  return async (dispatch) => {
    try {
      await axios.post(`/api/psychometric/question-views`, payload);
    } catch (error) {}
  };
};

export const handlePSYCheatings = (payload, exitFullScreen) => {
  return async (dispatch) => {
    try {
      await axios.post(`/api/psychometric/cheatings`, payload);

      exitFullScreen();
    } catch (error) {
      exitFullScreen();
    }
  };
};

export const getPsychometricSessionLogs = (applicantSessionId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(
        `/api/psychometric/session-logs/${applicantSessionId}`
      );

      dispatch({
        type: types.SET_PSYCHOMETRIC_SESSION_LOGS,
        data: data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};

export const getPsychometricInstructions = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/api/psychometric/instructions`);

      dispatch({
        type: types.SET_PSYCHOMETRIC_INSTRUCTIONS,
        data: data,
      });
    } catch (error) {
      dispatch({ type: types.END_LOADING });
      showError(error);
    }
  };
};
