export const defaultState = {
  psychometricLaguages: [],
  psychometricSession: null,
  psychometricQuestions: [],
  currentPSYQuestionNo: 0,
  questionTime: 0,
  psychometricSessionLogs: [],
  psychometricInstructions: [],
  feedbackQuestions: [],
};
